import React, { useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import '../../styles/tabs.css'; // Import the CSS file for animations

interface Tab {
  title: string;
  value: string;
  content: React.ReactNode;
}

interface TabsProps {
  tabs: Tab[];
}

export const Tabs: React.FC<TabsProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].value);

  return (
    <div className="w-full">
      <div className="flex border-b border-gray-200 mb-4">
        {tabs.map((tab) => (
          <button
            key={tab.value}
            className={`px-4 py-2 -mb-px text-sm font-medium text-center focus:outline-none ${
              activeTab === tab.value
                ? 'border-b-2 border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
            onClick={() => setActiveTab(tab.value)}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div className="p-4 bg-gray-50 rounded-lg shadow">
        <TransitionGroup>
          {tabs.map((tab) => (
            activeTab === tab.value && (
              <CSSTransition
                key={tab.value}
                timeout={300}
                classNames="fade"
              >
                <div className="tab-content">
                  {tab.content}
                </div>
              </CSSTransition>
            )
          ))}
        </TransitionGroup>
      </div>
    </div>
  );
};
