import React from "react";
import Hero from "./Hero";
import Dashboard from "./Dashboard";

const Main = () => {
  return (
    <div>
      <Hero loc="panel"/>
      <Dashboard id="panel"/>
    </div>
  );
};

export default Main;
