import React from 'react';
import { useUser } from '../../contexts/UserContext';

const AccessDeniedPopup: React.FC = () => {

  return (
    <div className="access-popup">
      Access not allowed
    </div>
  );
};

export default AccessDeniedPopup;
