import axios from "axios";
import { LampContainer } from "./ui/lamp";
import React, { useEffect, useId, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useOutsideClick } from "../hooks/use-outside-click";
import { useNavigate } from "react-router-dom";
import { useUser } from "../contexts/UserContext";

interface GCP {
  _id: string;
  title: string;
  name: string;
  email: string;
  portfolio: string;
  category: string;
  localCommittee: string;
  gcpDetails: string;
  gcpLink: string;
  image: string; // This should be the Google Drive direct link
  status: string;
  createdAt: string;
}

export const CloseIcon = () => {
  return (
    <motion.svg
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.05 } }}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="h-4 w-4 text-black"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M18 6l-12 12" />
      <path d="M6 6l12 12" />
    </motion.svg>
  );
};

const GCPList: React.FC = () => {
  const [gcps, setGcps] = useState<GCP[]>([]);
  const [filteredGCPs, setFilteredGCPs] = useState<GCP[]>([]);
  const [search, setSearch] = useState("");
  const [filterPortfolio, setFilterPortfolio] = useState("");
  const [error, setError] = useState("");
  const [active, setActive] = useState<GCP | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const ref = useRef<HTMLDivElement>(null);
  const id = useId();
  const { user, loading, role } = useUser();

  const navigate = useNavigate();
  useEffect(() => {
    const fetchGCPs = async () => {
      try {
        const res = await axios.get("https://api.nexusmxp.com/api/gcp", {
          params: { page: currentPage, limit: 8 },
        });
        setGcps(res.data.gcps);
        setTotalPages(res.data.totalPages);
      } catch (error) {
        console.error("Error fetching GCPs:", error);
        setError("Failed to fetch GCP entries");
      }
    };

    fetchGCPs();
  }, [currentPage]);

  useEffect(() => {
    const filterGCPs = () => {
      let filtered = gcps;
      if (search) {
        filtered = filtered.filter(
          (gcp) =>
            (gcp.title &&
              gcp.title.toLowerCase().includes(search.toLowerCase())) ||
            (gcp.portfolio &&
              gcp.portfolio.toLowerCase().includes(search.toLowerCase()))
        );
      }
      if (filterPortfolio) {
        filtered = filtered.filter((gcp) => gcp.portfolio === filterPortfolio);
      }
      setFilteredGCPs(filtered);
    };

    filterGCPs();
  }, [search, filterPortfolio, gcps]);

  useOutsideClick(ref, () => setActive(null));

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-4xl">
      <LampContainer>
        <motion.h1
          initial={{ opacity: 0.5, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.8, ease: "easeInOut" }}
          className="font-playfair bg-gradient-to-br from-slate-300 to-[#674188] py-4 bg-clip-text text-center text-4xl font-bold tracking-tight text-transparent md:text-7xl"
        >
          Good Case Practice
        </motion.h1>
      </LampContainer>
      {error && <p className="text-red-500">{error}</p>}

      <div className="flex justify-between mb-4 space-x-4 pb-10 pt-0">
        <input
          type="text"
          placeholder="Search by title or portfolio"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="border rounded px-4 py-2 w-full md:w-1/2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        />
        <select
          value={filterPortfolio}
          onChange={(e) => setFilterPortfolio(e.target.value)}
          className="border rounded px-4 py-2 w-full md:w-1/2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          <option value="">Filter by portfolio</option>
          {Array.from(new Set(gcps.map((gcp) => gcp.portfolio))).map(
            (portfolio) => (
              <option key={portfolio} value={portfolio}>
                {portfolio}
              </option>
            )
          )}
        </select>
      </div>

      <AnimatePresence>
        {active && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/20 h-full w-full z-10"
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {active && (
          <div className="fixed inset-0 grid place-items-center z-[100]">
            <motion.button
              key={`button-${active.title}-${id}`}
              layout
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, transition: { duration: 0.05 } }}
              className="flex absolute top-2 right-2 lg:hidden items-center justify-center bg-white rounded-full h-6 w-6"
              onClick={() => setActive(null)}
            >
              <CloseIcon />
            </motion.button>
            <motion.div
              layoutId={`card-${active.title}-${id}`}
              ref={ref}
              className="w-full max-w-[500px] h-full md:h-fit md:max-h-[90%] flex flex-col bg-white dark:bg-neutral-900 sm:rounded-3xl overflow-hidden"
            >
              <motion.div layoutId={`image-${active.title}-${id}`}>
                <img
                  src={active.image}
                  alt={active.title}
                  className="w-full h-80 lg:h-80 sm:rounded-tr-lg sm:rounded-tl-lg object-cover object-top"
                />
              </motion.div>

              <div>
                <div className="flex justify-between items-start p-4">
                  <div className="">
                    <motion.h3
                      layoutId={`title-${active.title}-${id}`}
                      className="font-bold text-neutral-700 dark:text-neutral-200"
                    >
                      {active.title}
                    </motion.h3>
                    <motion.h4
                      layoutId={`name-portfolio-${active._id}-${id}`}
                      className="text-neutral-600 dark:text-neutral-400"
                    >
                      {active.name} - {active.portfolio}
                    </motion.h4>
                    <motion.p
                      layoutId={`localCommittee-${active._id}-${id}`}
                      className="text-neutral-600 dark:text-neutral-400"
                    >
                      {active.localCommittee}
                    </motion.p>
                  </div>
                  <motion.a
                    layoutId={`button-${active.title}-${id}`}
                    href={active.gcpLink}
                    target="_blank"
                    className="px-4 py-3 text-sm rounded-full font-bold bg-green-500 text-white"
                  >
                    GCP Link
                  </motion.a>
                </div>

                <div className="pt-4 relative px-4">
                  <motion.div
                    layout
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="text-neutral-600 text-xs md:text-sm lg:text-base h-40 md:h-fit pb-10 flex flex-col items-start gap-4 overflow-auto dark:text-neutral-400 [mask:linear-gradient(to_bottom,white,white,transparent)] [scrollbar-width:none] [-ms-overflow-style:none] [-webkit-overflow-scrolling:touch]"
                  >
                    {active.gcpDetails}
                  </motion.div>
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>

      <ul className="max-w-2xl mx-auto w-full gap-4">
        {filteredGCPs.map((gcp, index) => (
          <motion.div
            layoutId={`card-${gcp.title}-${id}`}
            key={`card-${gcp.title}-${id}`}
            onClick={() => setActive(gcp)}
            className="p-4 flex flex-col md:flex-row justify-between items-center hover:bg-neutral-50 dark:hover:bg-neutral-800 rounded-xl cursor-pointer"
          >
            <div className="flex gap-4 flex-col md:flex-row ">
              <motion.div layoutId={`image-${gcp.title}-${id}`}>
                <img
                  src={gcp.image}
                  alt={gcp.title}
                  className="h-40 w-40 md:h-14 md:w-14 rounded-lg object-cover object-top"
                  onError={() => {
                    console.error(`Failed to load image: ${gcp.image}`);
                  }}
                />
              </motion.div>
              <div className="">
                <motion.h3
                  layoutId={`title-${gcp.title}-${id}`}
                  className="font-medium text-neutral-800 dark:text-neutral-200 text-center md:text-left"
                >
                  {gcp.title}
                </motion.h3>
                <motion.p
                  layoutId={`description-${gcp.gcpDetails}-${id}`}
                  className="text-neutral-600 dark:text-neutral-400 text-center md:text-left"
                >
                  {gcp.portfolio}
                </motion.p>
              </div>
            </div>
            <motion.button
              layoutId={`button-${gcp.title}-${id}`}
              className="px-4 py-2 text-sm rounded-full font-bold bg-gray-100 hover:bg-green-500 hover:text-white text-black mt-4 md:mt-0"
            >
              View GCP
            </motion.button>
            {(role === "admin" || role === "mcvp") && (
              <button
                onClick={() => navigate(`/edit-gcp/${gcp._id}`)}
                className="ml-4 px-4 py-2 bg-yellow-500 text-white rounded-lg shadow-md hover:bg-yellow-600 transition duration-200"
              >
                Edit
              </button>
            )}
          </motion.div>
        ))}
      </ul>

      <div className="flex justify-between mt-6 pb-5">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-indigo-600 text-white rounded-md disabled:opacity-50"
        >
          Previous
        </button>
        <button
          onClick={handleNextPage}
          disabled={currentPage >= totalPages}
          className="px-4 py-2 bg-indigo-600 text-white rounded-md disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default GCPList;
