import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PinContainer } from './ui/3d-pin';

interface APICycle {
  _id: string;
  title: string;
  description: string;
  documentUrl: string;
  imageUrl: string;
}

const APICycleHub = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [apiCycles, setAPICycles] = useState<APICycle[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchAPICycles = async () => {
      try {
        const response = await axios.get(`https://api.nexusmxp.com/api/api-cycles`, {
          params: { page, limit: 6 },
        });
        setAPICycles(response.data.data || []); // Ensure data is an array or fallback to an empty array
        setTotalPages(response.data.totalPages || 1); // Fallback to 1 if totalPages is undefined
      } catch (error) {
        console.error('Error fetching APIP:', error);
      }
    };
  
    fetchAPICycles();
  }, [page]);
  

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredAPICycles = apiCycles?.filter((apiCycle) =>
    apiCycle.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  return (
    <div className="py-20" id="api-cycles">
      <h1 className="text-6xl text-center font-playfair pb-6">
        APIP{` `}
        <span className="text-purple"> Cycle</span>
      </h1>
      <div className="flex justify-center mt-8 md-0 pd-0">
        <input
          type="text"
          placeholder="Search by name"
          value={searchTerm}
          onChange={handleSearchChange}
          className="px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500 w-[40%]"
        />
      </div>
      <div className="flex flex-wrap items-center justify-center  pt-0 p-4 gap-x-24 gap-y-8 xl:gap-x-24 lg:gap-x-12">
        {filteredAPICycles?.map(({ _id, title, description, documentUrl, imageUrl }) => (
          <div
            key={_id}
            className="sm:h-[41rem] h-[32rem] lg:min-h-[32.5rem] flex items-center justify-center sm:w-[570px] w-[80vw]"
          >
            <PinContainer title={title} href={documentUrl}>
              <div className="relative flex items-center justify-center sm:w-[570px] sm:h-[40vh] w-[80vw] overflow-hidden h-[30vh] mb-10">
                <div
                  className="relative w-full h-full overflow-hidden lg:rounded-3xl"
                  style={{ backgroundColor: '#13162D' }}
                >
                  <img src="/bg.png" alt="bg-img" />
                </div>
                <img
                  src={imageUrl}
                  alt={title}
                  className="z-10 absolute bottom-0"
                />
              </div>
              <h1 className="font-bold lg:text-2xl md:text-xl text-base line-clamp-1">
                {title}
              </h1>
              <p className="lg:text-xl lg:font-normal font-light text-sm line-clamp-2">
                {description}
              </p>
              <div className="flex items-center justify-between mt-7 mb-3">
                <div className="flex justify-center items-center">
                  <p className="flex lg:text-xl md:text-xs text-sm text-purple">
                    Check Document
                  </p>
                </div>
              </div>
            </PinContainer>
          </div>
        ))}
      </div>
      <div className="flex justify-center space-x-4 mt-8 font-semibold">
        <button
          onClick={handlePreviousPage}
          disabled={page === 1}
          className="px-4 py-2 rounded-lg bg-purple-600 text-black border border-gray-500"
        >
          Previous
        </button>
        <button
          onClick={handleNextPage}
          disabled={page === totalPages}
          className="px-4 py-2 rounded-lg bg-purple-600 text-black border border-gray-500"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default APICycleHub;


