import React, { useState } from "react";

interface FormData {
  firstName: string;
  lastName: string;
  dob: string;
  gender: string;
  nationality: string;
  cityOfResidence: string;
  cityOfStudy: string;
  cnic: string;
  contactNumber: string;
  personalEmail: string;
  alternateEmail: string;
  receiveInfo: string;
  contactMethod: string;
  guardianName: string;
  guardianContact: string;
  institute: string;
  otherInstitute: string; 
  areaOfStudy: string;
  degree: string;
  academicYear: string;
  enrolmentYear: string;
  graduationYear: string;
  linkedinProfile: string;
  facebookProfile: string;
  hearAboutAIESEC: string;
  preferredDepartment: string;
  aiesecReference: string;
  previousExchange: string;
  reasonForJoining: string;
  uniqueContribution: string;
  googleDocs: string;
  googleSheets: string;
  msWord: string;
  msPowerPoint: string;
  msExcel: string;
  canva: string;
}

const RecruitmentForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    lastName: "",
    dob: "",
    gender: "",
    nationality: "",
    cityOfResidence: "",
    cityOfStudy: "",
    cnic: "",
    contactNumber: "",
    personalEmail: "",
    alternateEmail: "",
    receiveInfo: "",
    contactMethod: "",
    guardianName: "",
    guardianContact: "",
    institute: "",
    otherInstitute: "", 
    areaOfStudy: "",
    degree: "",
    academicYear: "",
    enrolmentYear: "",
    graduationYear: "",
    linkedinProfile: "",
    facebookProfile: "",
    hearAboutAIESEC: "",
    preferredDepartment: "",
    aiesecReference: "",
    previousExchange: "",
    reasonForJoining: "",
    uniqueContribution: "",
    googleDocs: "5",
    googleSheets: "5",
    msWord: "5",
    msPowerPoint: "5",
    msExcel: "5",
    canva: "5",
  });
  const [cnicError, setCnicError] = useState<string | null>(null);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [contactNumberError, setContactNumberError] = useState<string | null>(
    null
  );
  const [guardianContactError, setGuardianContactError] = useState<
    string | null
  >(null);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [showOtherInput, setShowOtherInput] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string | null>(null); // New state for email error message


  

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;

    // Check CNIC format
    if (name === "cnic") {
      const cnicPattern = /^[0-9]{5}-[0-9]{7}-[0-9]{1}$/;
      if (!cnicPattern.test(value)) {
        setCnicError("CNIC must be in the format 00000-0000000-0");
      } else {
        setCnicError(null);
      }
    }

    // Check Contact Number format
    if (name === "contactNumber") {
      const contactPattern = /^03[0-9]{9}$/;
      if (!contactPattern.test(value)) {
        setContactNumberError(
          "Contact number must be in the format 03000000000"
        );
      } else {
        setContactNumberError(null);
      }
    }

    // Check Guardian's Contact Number format
    if (name === "guardianContact") {
      const contactPattern = /^03[0-9]{9}$/;
      if (!contactPattern.test(value)) {
        setGuardianContactError(
          "Guardian's contact number must be in the format 03000000000"
        );
      } else {
        setGuardianContactError(null);
      }
    }

    setFormData({ ...formData, [name]: value });
  };

  const validateStep = () => {
    const tempErrors: { [key: string]: string } = {}; // Temporary object to hold errors

    if (currentStep === 1) {
      // Step 1: Personal Information
      if (!formData.firstName.trim())
        tempErrors.firstName = "First Name is required";
      if (!formData.lastName.trim())
        tempErrors.lastName = "Last Name is required";
      if (!formData.dob) tempErrors.dob = "Date of Birth is required";
      if (!formData.gender) tempErrors.gender = "Gender is required";
      if (!formData.nationality.trim())
        tempErrors.nationality = "Nationality is required";
      if (!formData.cityOfResidence.trim())
        tempErrors.cityOfResidence = "City of Residence is required";
      if (!formData.cityOfStudy.trim())
        tempErrors.cityOfStudy = "City of Study is required";
    } else if (currentStep === 2) {
      // Step 2: Contact Information
      if (!formData.cnic.trim()) tempErrors.cnic = "CNIC is required";
      if (!formData.contactNumber.trim())
        tempErrors.contactNumber = "Contact Number is required";
      if (!formData.personalEmail.trim())
        tempErrors.personalEmail = "Personal Email is required";
      if (!formData.alternateEmail.trim())
        tempErrors.alternateEmail = "Alternate Email is required";
      if (!formData.contactMethod)
        tempErrors.contactMethod = "Preferred Method of Contact is required";
      if (!formData.receiveInfo)
        tempErrors.receiveInfo =
          "Please indicate if you want to receive further information about AIESEC";
    } else if (currentStep === 3) {
      // Step 3: Guardian Information
      if (!formData.guardianName.trim())
        tempErrors.guardianName = "Guardian's Full Name is required";
      if (!formData.guardianContact.trim())
        tempErrors.guardianContact = "Guardian's Contact Number is required";
    } else if (currentStep === 4) {
      // Step 4: Academic Information
      if (!formData.institute)
        tempErrors.institute = "Institute/University is required";
      if (!formData.areaOfStudy)
        tempErrors.areaOfStudy = "Area of Study is required";
      if (!formData.degree) tempErrors.degree = "Degree is required";
      if (!formData.academicYear)
        tempErrors.academicYear = "Current Academic Year is required";
      if (!formData.enrolmentYear)
        tempErrors.enrolmentYear = "Year of Enrolment is required";
      if (!formData.graduationYear)
        tempErrors.graduationYear = "Year of Graduation is required";
    } else if (currentStep === 5) {
      // Step 5: Social Profiles & AIESEC Information
      // if (!formData.linkedinProfile.trim())
      //   tempErrors.linkedinProfile = "LinkedIn Profile is required";
      // if (!formData.facebookProfile.trim())
      //   tempErrors.facebookProfile = "Facebook Profile is required";
      if (!formData.hearAboutAIESEC)
        tempErrors.hearAboutAIESEC =
          "Please indicate where you heard about AIESEC";
      if (!formData.preferredDepartment)
        tempErrors.preferredDepartment = "Preferred Department is required";
      if (!formData.previousExchange)
        tempErrors.previousExchange =
          "Please indicate if you have been on an exchange with AIESEC";
    } else if (currentStep === 6) {
      // Step 6: Final Information
      if (!formData.reasonForJoining.trim())
        tempErrors.reasonForJoining = "Reason for joining AIESEC is required";
      if (!formData.uniqueContribution.trim())
        tempErrors.uniqueContribution = "Your unique contribution is required";
    }

    // Set errors if any exist
    setErrors(tempErrors);

    // Return true if no errors, false otherwise
    return Object.keys(tempErrors).length === 0;
  };

  const steps = [
    {
      title: "",
      fields: (
        <>
          <div className="text-center">
            <h2 className="text-3xl font-bold text-blue-600 mb-4">
              AIESEC in Pakistan is NOW RECRUITING!
            </h2>
            <br/>
            <p className="text-lg mb-6 text-gray-700">
              AIESEC is a Global Platform for Young People to develop their
              Leadership Potential through practical experiences of many kinds,
              including internships, volunteering opportunities, and more. Founded
              in 1948, AIESEC is a Non-Governmental and Not-For-Profit
              Organization entirely run by the Youth for the Youth.
            </p>
            <div className="text-red-600 pd-10">
            <p>Form has been closed for listed LCs</p>
            <p>IBA - Closed</p>
            <p>Islamabad - Closed</p>
            <p>NUST - Closed</p>
            <p>LUMS - Closed</p>
            <p>Lahore - Closed</p>
            <p>Multan - Closed</p>
	    <p>Faisalabad - Closed</p>
	    </div>
          </div>
          <div className="flex justify-center">
            <img
              src="./banner2.png"
              alt="Banner"
              className="w-full h-auto rounded-lg max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg"
            />
          </div>
        </>
      ),
    }
    ,
    {
      title: "Personal Information",
      fields: (
        <>
          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">First Name:</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.firstName ? "border-red-500" : ""
              }`}
              required
            />
            {errors.firstName && (
              <p className="text-red-500 text-sm mt-1">{errors.firstName}</p>
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">Last Name:</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.lastName ? "border-red-500" : ""
              }`}
              required
            />
            {errors.lastName && (
              <p className="text-red-500 text-sm mt-1">{errors.lastName}</p>
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">Date of Birth:</label>
            <input
              type="date"
              name="dob"
              value={formData.dob}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.dob ? "border-red-500" : ""
              }`}
              required
            />
            {errors.dob && (
              <p className="text-red-500 text-sm mt-1">{errors.dob}</p>
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">Gender:</label>
            <select
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.gender ? "border-red-500" : ""
              }`}
              required
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Prefer Not to Say">Prefer Not to Say</option>
            </select>
            {errors.gender && (
              <p className="text-red-500 text-sm mt-1">{errors.gender}</p>
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">Nationality:</label>
            <input
              type="text"
              name="nationality"
              value={formData.nationality}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.nationality ? "border-red-500" : ""
              }`}
              required
            />
            {errors.nationality && (
              <p className="text-red-500 text-sm mt-1">{errors.nationality}</p>
            )}
          </div>

         <div className="flex flex-col mb-4">
  <label className="mb-1 font-medium">City of Residence:</label>
  <select
    name="cityOfResidence"
    value={formData.cityOfResidence}
    onChange={handleChange}
    className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
      errors.cityOfResidence ? "border-red-500" : ""
    }`}
    required
  >
    <option value="">Select City of Residence</option>
    <option value="Karachi">Karachi</option>
    <option value="Hyderabad">Hyderabad</option>
    <option value="Quetta">Quetta</option>
    <option value="Faisalabad">Faisalabad</option>
    <option value="Multan">Multan</option>
    <option value="Lahore">Lahore</option>
    <option value="Rawalpindi">Rawalpindi</option>
    <option value="Islamabad">Islamabad</option>
    <option value="Peshawar">Peshawar</option>
    <option value="Other">Other</option>
  </select>
  {errors.cityOfResidence && (
    <p className="text-red-500 text-sm mt-1">{errors.cityOfResidence}</p>
  )}
</div>

<div className="flex flex-col mb-4">
  <label className="mb-1 font-medium">City of Study:</label>
  <select
    name="cityOfStudy"
    value={formData.cityOfStudy}
    onChange={handleChange}
    className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
      errors.cityOfStudy ? "border-red-500" : ""
    }`}
    required
  >
    <option value="">Select City of Study</option>
    <option value="Hyderabad">Hyderabad</option>
    <option value="Karachi">Karachi</option>
    <option value="Swabi">Swabi/Topi</option>
  </select>
  {errors.cityOfStudy && (
    <p className="text-red-500 text-sm mt-1">{errors.cityOfStudy}</p>
  )}
</div>

        </>
      ),
    },
    {
      title: "Contact Information",
      fields: (
        <>
          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">CNIC:</label>
            <input
              type="text"
              name="cnic"
              value={formData.cnic}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.cnic || cnicError ? "border-red-500" : ""
              }`}
              required
            />
            {errors.cnic && (
              <p className="text-red-500 text-sm mt-1">{errors.cnic}</p>
            )}
            {cnicError && (
              <p className="text-red-500 text-sm mt-1">{cnicError}</p>
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">Contact Number:</label>
            <input
              type="text"
              name="contactNumber"
              value={formData.contactNumber}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.contactNumber || contactNumberError
                  ? "border-red-500"
                  : ""
              }`}
              required
            />
            {errors.contactNumber && (
              <p className="text-red-500 text-sm mt-1">
                {errors.contactNumber}
              </p>
            )}
            {contactNumberError && (
              <p className="text-red-500 text-sm mt-1">{contactNumberError}</p>
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">Personal Email:</label>
            <input
              type="email"
              name="personalEmail"
              value={formData.personalEmail}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.personalEmail ? "border-red-500" : ""
              }`}
              required
            />
            {errors.personalEmail && (
              <p className="text-red-500 text-sm mt-1">
                {errors.personalEmail}
              </p>
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">Alternate Email:</label>
            <input
              type="email"
              name="alternateEmail"
              value={formData.alternateEmail}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.alternateEmail ? "border-red-500" : ""
              }`}
              required
            />
            {errors.alternateEmail && (
              <p className="text-red-500 text-sm mt-1">
                {errors.alternateEmail}
              </p>
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">
              Preferred Method of Contact:
            </label>
            <select
              name="contactMethod"
              value={formData.contactMethod}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.contactMethod ? "border-red-500" : ""
              }`}
              required
            >
              <option value="">Select Method</option>
              <option value="Call">Call</option>
              <option value="SMS">SMS</option>
              <option value="WhatsApp">WhatsApp</option>
              <option value="Email">Email</option>
            </select>
            {errors.contactMethod && (
              <p className="text-red-500 text-sm mt-1">
                {errors.contactMethod}
              </p>
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">
              Do you agree to receive further information regarding AIESEC?:
            </label>
            <select
              name="receiveInfo"
              value={formData.receiveInfo}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.receiveInfo ? "border-red-500" : ""
              }`}
              required
            >
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            {errors.receiveInfo && (
              <p className="text-red-500 text-sm mt-1">{errors.receiveInfo}</p>
            )}
          </div>
        </>
      ),
    },
    {
      title: "Guardian Information",
      fields: (
        <>
          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">Guardian's Full Name:</label>
            <input
              type="text"
              name="guardianName"
              value={formData.guardianName}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.guardianName ? "border-red-500" : ""
              }`}
              required
            />
            {errors.guardianName && (
              <p className="text-red-500 text-sm mt-1">{errors.guardianName}</p>
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">
              Guardian's Contact Number:
            </label>
            <input
              type="text"
              name="guardianContact"
              value={formData.guardianContact}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.guardianContact || guardianContactError
                  ? "border-red-500"
                  : ""
              }`}
              required
            />
            {errors.guardianContact && (
              <p className="text-red-500 text-sm mt-1">
                {errors.guardianContact}
              </p>
            )}
            {guardianContactError && (
              <p className="text-red-500 text-sm mt-1">
                {guardianContactError}
              </p>
            )}
          </div>
        </>
      ),
    },
    {
      title: "Academic Information",
      fields: (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div className="flex flex-col mb-4">
    <label className="mb-1 font-medium">Institute/University:</label>
    <select
      name="institute"
      value={formData.institute}
      onChange={(e) => {
        handleChange(e);
        if (e.target.value === "Other") {
          setShowOtherInput(true); // Show the input box when "Other" is selected
        } else {
          setShowOtherInput(false); // Hide the input box when another option is selected
        }
      }}
      className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
        errors.institute ? "border-red-500" : ""
      }`}
      required
    >
    <option value="">Select Institute/University</option>
    <option value="GIKI">GIKI</option>
    <option value="Aga Khan University - Karachi">
      Aga Khan University - Karachi
    </option>
    <option value="AIFD - Karachi">AIFD - Karachi</option>
    <option value="Bahria University - Karachi">
      Bahria University - Karachi
    </option>
    <option value="Baqai Medical University - Karachi">
      Baqai Medical University - Karachi
    </option>
    <option value="FAST-NUCES - Karachi">FAST-NUCES - Karachi</option>
    <option value="Greenwich University - Karachi">
      Greenwich University - Karachi
    </option>
    <option value="Habib University - Karachi">
      Habib University - Karachi
    </option>
    <option value="Hamdard University - Karachi">
      Hamdard University - Karachi
    </option>
    <option value="Indus University - Karachi">
      Indus University - Karachi
    </option>
    <option value="Institute of Business Management - Karachi">
      Institute of Business Management - Karachi
    </option>
    <option value="Iqra University - Karachi">
      Iqra University - Karachi
    </option>
    <option value="Karachi School of Business & Leadership (KSBL) - Karachi">
      Karachi School of Business & Leadership (KSBL) - Karachi
    </option>
    <option value="Karachi University - Karachi">
      Karachi University - Karachi
    </option>
    <option value="NEDUET - Karachi">NEDUET - Karachi</option>
    <option value="NUST PNEC - Karachi">NUST PNEC - Karachi</option>
    <option value="Sir Syed University - Karachi">
      Sir Syed University - Karachi
    </option>
    <option value="Textile Institute of Pakistan - Karachi">
      Textile Institute of Pakistan - Karachi
    </option>
    <option value="TMUC - Karachi">TMUC - Karachi</option>
    <option value="UIT - Karachi">UIT - Karachi</option>
    <option value="Ziauddin University - Karachi">
      Ziauddin University - Karachi
    </option>
    <option value="Altamash Institute of Dental Medicine - Karachi South">
      Altamash Institute of Dental Medicine - Karachi South
    </option>
    <option value="Barret Hodgson - Karachi South">
      Barret Hodgson - Karachi South
    </option>
    <option value="Dennings Law School - Karachi South">
      Dennings Law School - Karachi South
    </option>
    <option value="DHA Suffa - Karachi South">
      DHA Suffa - Karachi South
    </option>
    <option value="ILMA University - Karachi South">
      ILMA University - Karachi South
    </option>
    <option value="IVS - Karachi South">IVS - Karachi South</option>
    <option value="Jinnah Sindh Medical University - Karachi South">
      Jinnah Sindh Medical University - Karachi South
    </option>
    <option value="KITE - Karachi South">KITE - Karachi South</option>
    <option value="MiTE University - Karachi South">
      MiTE University - Karachi South
    </option>
    <option value="Muhammad Ali Jinnah University - Karachi South">
      Muhammad Ali Jinnah University - Karachi South
    </option>
    <option value="PAF-KIET - Karachi South">
      PAF-KIET - Karachi South
    </option>
    <option value="SZABIST Karachi - Karachi South">
      SZABIST Karachi - Karachi South
    </option>
    <option value="Ziauddin University - Karachi South">
      Ziauddin University - Karachi South
    </option>
    <option value="Everest Law College - Hyderabad">
      Everest Law College - Hyderabad
    </option>
    <option value="HIAST Institute - Hyderabad">
      HIAST Institute - Hyderabad
    </option>
    <option value="IBA Sukkar Campus - Hyderabad">
      IBA Sukkar Campus - Hyderabad
    </option>
    <option value="IMSA - Hyderabad">IMSA - Hyderabad</option>
    <option value="Indus Law College - Hyderabad">
      Indus Law College - Hyderabad
    </option>
    <option value="Indus Medical College - Hyderabad">
      Indus Medical College - Hyderabad
    </option>
    <option value="Isra University - Hyderabad">
      Isra University - Hyderabad
    </option>
    <option value="LUMHS University - Hyderabad">
      LUMHS University - Hyderabad
    </option>
    <option value="Mehran University - Hyderabad">
      Mehran University - Hyderabad
    </option>
    <option value="NUML - Hyderabad">NUML - Hyderabad</option>
    <option value="PUMHS Nawabshah - Hyderabad">
      PUMHS Nawabshah - Hyderabad
    </option>
    <option value="Sindh University - Hyderabad">
      Sindh University - Hyderabad
    </option>
    <option value="SZABIST Hyderabad - Hyderabad">
      SZABIST Hyderabad - Hyderabad
    </option>
     <option value="Other">Other</option>
    </select>
    {errors.institute && (
      <p className="text-red-500 text-sm mt-1">{errors.institute}</p>
    )}
  </div>

  {/* Conditionally render the input box if "Other" is selected */}
  {showOtherInput && (
    <div className="flex flex-col mb-4">
      <label className="mb-1 font-medium">Please specify:</label>
      <input
        type="text"
        name="otherInstitute"
        value={formData.otherInstitute}
        onChange={handleChange}
        className="p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        required
      />
      {errors.otherInstitute && (
        <p className="text-red-500 text-sm mt-1">{errors.otherInstitute}</p>
      )}
    </div>
  )}

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">Area of Study:</label>
            <select
              name="areaOfStudy"
              value={formData.areaOfStudy}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.areaOfStudy ? "border-red-500" : ""
              }`}
              required
            >
              <option value="">Select Area of Study</option>
              <option value="Medicine">Medicine</option>
              <option value="Engineering">Engineering</option>
              <option value="Computer Science">Computer Science</option>
              <option value="Business Administration">
                Business Administration
              </option>
              <option value="Accounting & Finance">Accounting & Finance</option>
              <option value="Social Sciences">Social Sciences</option>
              <option value="Arts & Literature">Arts & Literature</option>
              <option value="Law">Law</option>
              <option value="Media Sciences">Media Sciences</option>
              <option value="English Literature">English Literature</option>
              <option value="Other">Other</option>
            </select>
            {errors.areaOfStudy && (
              <p className="text-red-500 text-sm mt-1">{errors.areaOfStudy}</p>
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">Degree:</label>
            <select
              name="degree"
              value={formData.degree}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.degree ? "border-red-500" : ""
              }`}
              required
            >
              <option value="">Select Degree</option>
              <option value="Undergraduate">Undergraduate</option>
              <option value="Graduate">Graduate</option>
            </select>
            {errors.degree && (
              <p className="text-red-500 text-sm mt-1">{errors.degree}</p>
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">Current Academic Year:</label>
            <select
              name="academicYear"
              value={formData.academicYear}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.academicYear ? "border-red-500" : ""
              }`}
              required
            >
              <option value="">Select Year</option>
              <option value="1st Year (Freshman)">1st Year (Freshman)</option>
              <option value="2nd Year (Sophomore)">2nd Year (Sophomore)</option>
              <option value="3rd Year (Junior)">3rd Year (Junior)</option>
              <option value="4th Year (Senior)">4th Year (Senior)</option>
            </select>
            {errors.academicYear && (
              <p className="text-red-500 text-sm mt-1">{errors.academicYear}</p>
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">Year of Enrolment:</label>
            <input
              type="date"
              name="enrolmentYear"
              value={formData.enrolmentYear}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.enrolmentYear ? "border-red-500" : ""
              }`}
              required
            />
            {errors.enrolmentYear && (
              <p className="text-red-500 text-sm mt-1">
                {errors.enrolmentYear}
              </p>
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">Year of Graduation:</label>
            <input
              type="date"
              name="graduationYear"
              value={formData.graduationYear}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.graduationYear ? "border-red-500" : ""
              }`}
              required
            />
            {errors.graduationYear && (
              <p className="text-red-500 text-sm mt-1">
                {errors.graduationYear}
              </p>
            )}
          </div>
        </div>
      ),
    },
    {
      title: "Social Profiles & AIESEC Information",
      fields: (
        <>
          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">LinkedIn Profile:</label>
            <input
              type="url"
              name="linkedinProfile"
              value={formData.linkedinProfile}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.linkedinProfile ? "border-red-500" : ""
              }`}
              required
            />
            {errors.linkedinProfile && (
              <p className="text-red-500 text-sm mt-1">
                {errors.linkedinProfile}
              </p>
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">Facebook Profile:</label>
            <input
              type="url"
              name="facebookProfile"
              value={formData.facebookProfile}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.facebookProfile ? "border-red-500" : ""
              }`}
              required
            />
            {errors.facebookProfile && (
              <p className="text-red-500 text-sm mt-1">
                {errors.facebookProfile}
              </p>
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">
              Where did you hear about AIESEC?:
            </label>
            <select
              name="hearAboutAIESEC"
              value={formData.hearAboutAIESEC}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.hearAboutAIESEC ? "border-red-500" : ""
              }`}
              required
            >
              <option value="">Select</option>
              <option value="University Stall/Session">
                University Stall/Session
              </option>
              <option value="Friend">Friend</option>
              <option value="Social Media">Social Media</option>
              <option value="Website">Website</option>
              <option value="Other">Other</option>
            </select>
            {errors.hearAboutAIESEC && (
              <p className="text-red-500 text-sm mt-1">
                {errors.hearAboutAIESEC}
              </p>
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">Preferred Department:</label>
            <select
              name="preferredDepartment"
              value={formData.preferredDepartment}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.preferredDepartment ? "border-red-500" : ""
              }`}
              required
            >
              <option value="">Select Department</option>
              <option value="Business Development">Business Development</option>
              <option value="Marketing">Marketing</option>
              <option value="Sales">Sales</option>
              <option value="Human Resource">Human Resource</option>
              <option value="Finance">Finance</option>
            </select>
            {errors.preferredDepartment && (
              <p className="text-red-500 text-sm mt-1">
                {errors.preferredDepartment}
              </p>
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">
              Do you have any AIESEC reference?:
            </label>
            <input
              type="text"
              name="aiesecReference"
              value={formData.aiesecReference}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.aiesecReference ? "border-red-500" : ""
              }`}
            />
            {errors.aiesecReference && (
              <p className="text-red-500 text-sm mt-1">
                {errors.aiesecReference}
              </p>
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">
              Have you been on an Exchange with AIESEC?:
            </label>
            <select
              name="previousExchange"
              value={formData.previousExchange}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.previousExchange ? "border-red-500" : ""
              }`}
              required
            >
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            {errors.previousExchange && (
              <p className="text-red-500 text-sm mt-1">
                {errors.previousExchange}
              </p>
            )}
          </div>
        </>
      ),
    },
    {
      title: "Final Information",
      fields: (
        <>
          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">
              Why do you want to join AIESEC?:
            </label>
            <textarea
              name="reasonForJoining"
              value={formData.reasonForJoining}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.reasonForJoining ? "border-red-500" : ""
              }`}
              required
            />
            {errors.reasonForJoining && (
              <p className="text-red-500 text-sm mt-1">
                {errors.reasonForJoining}
              </p>
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">
              What is the unique contribution you will bring to AIESEC?:
            </label>
            <textarea
              name="uniqueContribution"
              value={formData.uniqueContribution}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.uniqueContribution ? "border-red-500" : ""
              }`}
              required
            />
            {errors.uniqueContribution && (
              <p className="text-red-500 text-sm mt-1">
                {errors.uniqueContribution}
              </p>
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">
              Google Docs Skill (0-10):
            </label>
            <input
              type="range"
              name="googleDocs"
              min="0"
              max="10"
              value={formData.googleDocs}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.googleDocs ? "border-red-500" : ""
              }`}
            />
            {errors.googleDocs && (
              <p className="text-red-500 text-sm mt-1">{errors.googleDocs}</p>
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">
              Google Sheets Skill (0-10):
            </label>
            <input
              type="range"
              name="googleSheets"
              min="0"
              max="10"
              value={formData.googleSheets}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.googleSheets ? "border-red-500" : ""
              }`}
            />
            {errors.googleSheets && (
              <p className="text-red-500 text-sm mt-1">{errors.googleSheets}</p>
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">
              Microsoft Word Skill (0-10):
            </label>
            <input
              type="range"
              name="msWord"
              min="0"
              max="10"
              value={formData.msWord}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.msWord ? "border-red-500" : ""
              }`}
            />
            {errors.msWord && (
              <p className="text-red-500 text-sm mt-1">{errors.msWord}</p>
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">
              Microsoft PowerPoint Skill (0-10):
            </label>
            <input
              type="range"
              name="msPowerPoint"
              min="0"
              max="10"
              value={formData.msPowerPoint}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.msPowerPoint ? "border-red-500" : ""
              }`}
            />
            {errors.msPowerPoint && (
              <p className="text-red-500 text-sm mt-1">{errors.msPowerPoint}</p>
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">
              Microsoft Excel Skill (0-10):
            </label>
            <input
              type="range"
              name="msExcel"
              min="0"
              max="10"
              value={formData.msExcel}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.msExcel ? "border-red-500" : ""
              }`}
            />
            {errors.msExcel && (
              <p className="text-red-500 text-sm mt-1">{errors.msExcel}</p>
            )}
          </div>

          <div className="flex flex-col mb-4">
            <label className="mb-1 font-medium">Canva Skill (0-10):</label>
            <input
              type="range"
              name="canva"
              min="0"
              max="10"
              value={formData.canva}
              onChange={handleChange}
              className={`p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                errors.canva ? "border-red-500" : ""
              }`}
            />
            {errors.canva && (
              <p className="text-red-500 text-sm mt-1">{errors.canva}</p>
            )}
          </div>
        </>
      ),
    },
    {
      title: "Form Submitted",
      fields: (
        <div className="text-center">
          <h3 className="text-2xl font-bold mb-4">Thank you!</h3>
          <p>Your form has been successfully submitted.</p>
          {isSubmitted && <p>Submitted</p>}
        </div>
      ),
    },
  ];

//   const handleSubmit = async (e: React.FormEvent) => {
//     e.preventDefault();

//     try {
//       await fetch(
//         "https://script.google.com/macros/s/AKfycbwHVj6EjjYF742ETNpKXs9NZ_n7LX3a-DB5Bi_CXPXWFtz7XaiE--uc480M851ONmc3MA/exec",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(formData),
//           mode: "no-cors", // Disable CORS to avoid cross-origin issues
//         }
//       );

//       setIsSubmitted(true); // Update submission status
//       setCurrentStep((prev) => prev + 1); // Move to the final step
//     } catch (error) {
//       console.error("Error submitting form:", error);
//       alert("Failed to submit the form.");
//     }
//   };

//   const nextStep = () => {
//     if (validateStep()) {
//       setCurrentStep((prev) => prev + 1);
//     } else {
//       return <p>Complete all the fields</p>;
//     }
//   };

//   const prevStep = () => setCurrentStep((prev) => prev - 1);

//   return (
//     <div className="min-h-screen flex items-center justify-center relative overflow-hidden">
//       {/* Left half - White background */}
//       <div className="absolute left-0 top-0 w-1/2 h-full bg-neutral-100">
//         <div className="fixed top-20 left-4"></div>
//       </div>

//       {/* Right half - Blue background */}
//       <div className="absolute right-0 top-0 w-1/2 h-full bg-blue-500"></div>

//       {/* Centered form */}
//       <div className="relative z-10 max-w-2xl mx-auto p-8 bg-white rounded-xl shadow-md">
//         <div className="flex flex-col items-center">
//           <h2 className="text-4xl font-semibold text-center mb-4 font-serif">
//             AIESEC in Pakistan
//           </h2>
//           {/* <h2 className="text-4xl font-semibold text-center mb-4 font-serif">
//           Fall 24.25 Recruitment Form
//           </h2> */}

//           <img
//             src="./banner.png"
//             alt="Banner"
//             className="w-full rounded-lg h-auto max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg mb-4"
//           />
//           {!isSubmitted && (
//             <p className="pb-5">
//               Make sure to fill every field or else your form will not be
//               accepted
//             </p>
//           )}
//         </div>

//         <form onSubmit={handleSubmit}>
//           <div className="mb-6">
//             <h3 className="text-xl font-medium mb-4">
//               {steps[currentStep].title}
//             </h3>
//             {steps[currentStep].fields}
//           </div>
//           <div className="flex justify-between items-center">
//   {/* Previous Button */}
//   {currentStep > 0 && (
//     <button
//       type="button"
//       className="bg-gray-400 text-white py-2 px-4 rounded-lg hover:bg-gray-500 transition duration-200"
//       onClick={prevStep}
//     >
//       Previous
//     </button>
//   )}
  
//   {/* Center Logo */}
//   <img src="./aieseclogo.png" alt="AIESEC" className="w-20 mx-auto" />

//   {/* Next Button or Submit Button */}
//   {currentStep < steps.length - 2 && (
//     <button
//       type="button"
//       className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-200"
//       onClick={nextStep}
//     >
//       Next
//     </button>
//   )}
//   {currentStep === steps.length - 2 && (
//     <button
//       type="submit"
//       className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 transition duration-200"
//     >
//       Submit
//     </button>
//   )}
// </div>

//         </form>
//       </div>
//     </div>
//   );
// };

// export default RecruitmentForm;

const handleSubmit = async (e: React.FormEvent) => {
  e.preventDefault();
  setLoading(true); // Start loading

  try {
    // Check if email already exists
    const response = await fetch(
      `https://script.google.com/macros/s/AKfycbxra6Z0wSj011wLeU-ti9DW3Hzn3GmXCgVPxFw8xeCHmFB-i3LLtghSYE6jx0hEepM--A/exec?email=${formData.personalEmail}`
    );
    const data = await response.json();

    if (data.emailExists) {
      setEmailError("This email already exists. Please use a different email."); // Set email error message
      setLoading(false); // Stop loading
      return;
    }

    // If email does not exist, proceed with form submission
    await fetch(
      "https://script.google.com/macros/s/AKfycbxra6Z0wSj011wLeU-ti9DW3Hzn3GmXCgVPxFw8xeCHmFB-i3LLtghSYE6jx0hEepM--A/exec",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
        mode: "no-cors", // Disable CORS to avoid cross-origin issues
      }
    );

    setIsSubmitted(true); // Update submission status
    setCurrentStep((prev) => prev + 1); // Move to the final step
  } catch (error) {
    console.error("Error submitting form:", error);
    alert("Failed to submit the form.");
  } finally {
    setLoading(false); // Stop loading
  }
};

const nextStep = () => {
  if (validateStep()) {
    setEmailError(null); // Reset email error when moving to the next step
    setCurrentStep((prev) => prev + 1);
  }
};

const prevStep = () => setCurrentStep((prev) => prev - 1);

return (
  <div className="min-h-screen flex items-center justify-center relative overflow-hidden">
    {/* Left half - White background */}
    <div className="absolute left-0 top-0 w-1/2 h-full bg-neutral-100">
      <div className="fixed top-20 left-4"></div>
    </div>

    {/* Right half - Blue background */}
    <div className="absolute right-0 top-0 w-1/2 h-full bg-blue-500"></div>

    {/* Centered form */}
    <div className="relative z-10 max-w-2xl mx-auto p-8 bg-white rounded-xl shadow-md">
      {loading ? (
        <div className="text-center">
          <h3 className="text-2xl font-bold mb-4">Submitting...</h3>
          <p>Please wait while we process your submission.</p>
          {/* You can also add a spinner or any other loading indicator here */}
        </div>
      ) : (
        <>
          <div className="flex flex-col items-center">
            <h2 className="text-4xl font-semibold text-center mb-4 font-serif">
              AIESEC in Pakistan
            </h2>
            <img
              src="./banner.png"
              alt="Banner"
              className="w-full rounded-lg h-auto max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg mb-4"
            />
            {!isSubmitted && (
              <p className="pb-5">
                Make sure to fill every field or else your form will not be
                accepted
              </p>
            )}
          </div>

          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <h3 className="text-xl font-medium mb-4">
                {steps[currentStep].title}
              </h3>
              {steps[currentStep].fields}

              {/* Display email error message */}
              {emailError && (
                <div className="text-center mt-4">
                  <p className="text-red-500">{emailError}</p>
                </div>
              )}
            </div>
            <div className="flex justify-between items-center">
              {currentStep > 0 && (
                <button
                  type="button"
                  className="bg-gray-400 text-white py-2 px-4 rounded-lg hover:bg-gray-500 transition duration-200"
                  onClick={prevStep}
                >
                  Previous
                </button>
              )}

              <img
                src="./aieseclogo.png"
                alt="AIESEC"
                className="w-20 mx-auto"
              />

              {currentStep < steps.length - 2 && (
                <button
                  type="button"
                  className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-200"
                  onClick={nextStep}
                >
                  Next
                </button>
              )}
              {currentStep === steps.length - 2 && (
                <button
                  type="submit"
                  className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 transition duration-200"
                  disabled={loading} // Disable button while loading
                >
                  Submit
                </button>
              )}
            </div>
          </form>

          {/* Display success message if submitted */}
          {currentStep === steps.length - 1 && isSubmitted && (
            <div className="text-center mt-4">
              <p>Your form has been successfully submitted.</p>
            </div>
          )}
        </>
      )}
    </div>
  </div>
);
};

export default RecruitmentForm;
