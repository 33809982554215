import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
const firebaseConfig = {
  apiKey: "AIzaSyB7o2YHoptnzMzR1M_MSsG0aK3LpwrYPuU",
  authDomain: "aiesec-nexus.firebaseapp.com",
  projectId: "aiesec-nexus",
  storageBucket: "aiesec-nexus.appspot.com",
  messagingSenderId: "334397025480",
  appId: "1:334397025480:web:3947191b2c6d8fd75b09fd",
  measurementId: "G-QS2029DCB8"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const db = getFirestore(app);
const storage = getStorage(app); // Make sure storage is exported here

export { auth, googleProvider, db, storage };
