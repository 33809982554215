import { useNavigate } from "react-router-dom";
import { Button } from "../components/ui/moving-border";

export const Skeleton1 = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="bg-[#F5F5F7] dark:bg-neutral-800 p-8 md:p-14 rounded-3xl mb-4">
        <p className="text-neutral-600 dark:text-neutral-400 text-base md:text-2xl font-sans max-w-3xl mx-auto">
          <span className="font-bold text-neutral-700 dark:text-neutral-200">
            A Good Case Practice (GCP) in AIESEC is a documented example of
            successful strategies or initiatives implemented by AIESEC entities,
            serving as a benchmark for others. It showcases effective solutions
            and innovative approaches in areas like recruitment, branding, or
            member engagement, providing insights and lessons that can be
            replicated or adapted by other teams to enhance their performance
            and achieve their goals.
          </span>{" "}
        </p>
        <div className="w-full flex justify-center pt-10">
          <Button
            onClick={() => {
              document.body.style.overflow = "auto";
              navigate("/gcplist");
            }}
            borderRadius="1.75rem"
            className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800 border-black"
          >
            GCP Hub
          </Button>
          <Button
            onClick={() => {
              document.body.style.overflow = "auto";
              navigate("/gcpform");
            }}
            borderRadius="1.75rem"
            className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800 border-black"
          >
            Add GCP
          </Button>
        </div>
      </div>
    </>
  );
};
export const Skeleton2 = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="bg-[#F5F5F7] dark:bg-neutral-800 p-8 md:p-14 rounded-3xl mb-4">
        <p className="text-neutral-600 dark:text-neutral-400 text-base md:text-2xl font-sans max-w-3xl mx-auto">
          <span className="font-bold text-neutral-700 dark:text-neutral-200">
            The APIP Cycle is a structured educational initiative by AIESEC
            designed to effectively downscale and disseminate vital information
            across the organization. It serves as a crucial framework for
            ensuring that knowledge, strategies, and best practices are
            systematically shared, enabling members at all levels to stay
            informed, aligned, and empowered to take meaningful action. Through
            the APIP Cycle, AIESEC fosters a culture of continuous learning and
            development, ensuring that the organization's objectives are met
            with consistency and excellence.
          </span>{" "}
        </p>
        <div className="w-full flex justify-center pt-10">
          <Button
            onClick={() => {
              document.body.style.overflow = "auto";
              navigate("/apip-cycle");
            }}
            borderRadius="1.75rem"
            className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800 border-black"
          >
            APIP Cycle
          </Button>
        </div>
      </div>
    </>
  );
};
export const Skeleton3 = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="bg-[#F5F5F7] dark:bg-neutral-800 p-8 md:p-14 rounded-3xl mb-4">
        <p className="text-neutral-600 dark:text-neutral-400 text-base md:text-2xl font-sans max-w-3xl mx-auto">
          <span className="font-bold text-neutral-700 dark:text-neutral-200">
            The Resource Hub is a centralised platform designed to support
            AIESEC leaders and members in delivering a high-quality membership
            experience. It houses essential documents, including the AIESEC Blue
            Code, MX Funnel Guidelines, MX Standards Guidelines, OD Guidelines,
            MX Processes, OD processes, Compendium and Ethics Booklet etc. This
            hub provides all the resources needed to understand and implement
            AIESEC’s standards, processes, and values effectively.
          </span>{" "}
        </p>
        <div className="w-full flex justify-center pt-10">
          <Button
            onClick={() => {
              document.body.style.overflow = "auto";
              navigate("/resource-hub");
            }}
            borderRadius="1.75rem"
            className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800 border-black "
          >
            Resource Hub
          </Button>
        </div>
      </div>
    </>
  );
};
export const Skeleton4 = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="bg-[#F5F5F7] dark:bg-neutral-800 p-8 md:p-14 rounded-3xl mb-4">
        <p className="text-neutral-600 dark:text-neutral-400 text-base md:text-2xl font-sans max-w-3xl mx-auto">
          <span className="font-bold text-neutral-700 dark:text-neutral-200">
            The MX Funnel is a comprehensive tool designed to track the number
            of Team Leaders (TLs) across each Local Committee (LC). It provides
            detailed statistics and insights, enabling AIESEC entities to
            monitor leadership pipelines, measure growth, and identify trends
            within their teams. This tool is essential for ensuring that the
            right leadership capacities are maintained, fostering continuous
            development and operational efficiency within the organization.
          </span>{" "}
        </p>
        <div className="w-full flex justify-center pt-10">
          <Button
            onClick={() => {
              document.body.style.overflow = "auto";
              navigate("/mx-funnel");
            }}
            borderRadius="1.75rem"
            className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800 border-black"
          >
            MX Funnel
          </Button>
          <Button
            onClick={() => {
              document.body.style.overflow = "auto";
              navigate("/mx-funnel-form");
            }}
            borderRadius="1.75rem"
            className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800 border-black"
          >
            MX Funnel Form
          </Button>
        </div>
      </div>
    </>
  );
};

export const Skeleton5 = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="bg-[#F5F5F7] dark:bg-neutral-800 p-8 md:p-14 rounded-3xl mb-4">
        <p className="text-neutral-600 dark:text-neutral-400 text-base md:text-2xl font-sans max-w-3xl mx-auto">
          <span className="font-bold text-neutral-700 dark:text-neutral-200">
            The AIESEC MX Standards are a set of 16 guidelines designed to
            ensure a high-quality and consistent membership experience across
            all teams. Their purpose is for every Team Leader to meticulously
            track their members' experience, ensuring that each member's
            development, engagement, and performance are prioritized. The MX
            Standards cover both the inner and outer journey of members, placing
            them at the center of the process. This comprehensive approach is to
            be implemented over a 6-month period, ensuring that every member's
            growth is facilitated effectively.
          </span>{" "}
        </p>
        <div className="w-full flex justify-center pt-10">
          <Button
            onClick={() => {
              document.body.style.overflow = "auto";
              navigate("/mx-standards");
            }}
            borderRadius="1.75rem"
            className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800 border-black"
          >
            MX Standards
          </Button>
          <Button
            onClick={() => {
              document.body.style.overflow = "auto";
              navigate("/mx-results");
            }}
            borderRadius="1.75rem"
            className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800 border-black"
          >
            MX Results
          </Button>
        </div>
      </div>
    </>
  );
};

export const Skeleton6 = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="bg-[#F5F5F7] dark:bg-neutral-800 p-8 md:p-14 rounded-3xl mb-4">
        <p className="text-neutral-600 dark:text-neutral-400 text-base md:text-2xl font-sans max-w-3xl mx-auto">
          <span className="font-bold text-neutral-700 dark:text-neutral-200">
            The OD model is a guide for the entity to understand its current
            state and set the path to mature and grow. An OD model with a clear
            index system serves as a diagnosis and guidance definition tool for
            LCs to understand where to focus on to reach their ideal state and
            contribute to the entity’s growth in operations and resources. It
            can be indicative of metrics through a product development index
            (PDI) and health indicator index (HDI) or consist of a performance
            index measuring RE achievement.
          </span>{" "}
        </p>
        <div className="w-full flex justify-center pt-10">
          <Button
            onClick={() => {
              document.body.style.overflow = "auto";
              navigate("/od-model");
            }}
            borderRadius="1.75rem"
            className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800 border-black"
          >
            OD Model
          </Button>
        </div>
      </div>
    </>
  );
};

export const Skeleton7 = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="bg-[#F5F5F7] dark:bg-neutral-800 p-8 md:p-14 rounded-3xl mb-4">
        <p className="text-neutral-600 dark:text-neutral-400 text-base md:text-2xl font-sans max-w-3xl mx-auto">
          <span className="font-bold text-neutral-700 dark:text-neutral-200">
            The “State of the Nation Assessment” or SONA Survey in AIESEC is a
            tool used to assess and measure the quality of the membership
            experience at different levels of the organization. It helps track
            how well AIESEC entities are implementing the MX Standards and other
            key processes.
          </span>{" "}
        </p>
        <div className="w-full flex justify-center pt-10">
          <Button
            onClick={() => {
              document.body.style.overflow = "auto";
              navigate("/sona-survey");
            }}
            borderRadius="1.75rem"
            className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800 border-black"
          >
            Sona Survey
          </Button>
        </div>
      </div>
    </>
  );
};
