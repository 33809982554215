// import React, { useState, useEffect } from "react";
// import axios from "axios";

// interface Metrics {
//   Leads: string;
//   Applicants: string;
//   Accepted: string;
//   Approved: string;
//   Realised: string;
//   Finished: string;
//   Completes: string;
//   Advanced: string;
//   Alumni: string;
// }

// const MXFunnelForm: React.FC = () => {
//   const [lc, setLc] = useState<string>("");
//   const [term, setTerm] = useState<string>("");
//   const [role, setRole] = useState<string>("");
//   const [customRole, setCustomRole] = useState<string>("");
//   const [portfolio, setPortfolio] = useState<string>("");
//   const [metrics, setMetrics] = useState<Metrics>({
//     Leads: "",
//     Applicants: "",
//     Accepted: "",
//     Approved: "",
//     Realised: "",
//     Finished: "",
//     Completes: "",
//     Advanced: "",
//     Alumni: "",
//   });

//   const lcOptions = [
//     "Multan",
//     "LUMS",
//     "IBA",
//     "Islamabad",
//     "NUST",
//     "South Karachi",
//     "Karachi",
//     "Hyderabad",
//     "Lahore",
//     "Faisalabad",
//     "Giki",
//     "MC", // Added MC option
//   ];

//   const roles: { [key: string]: string[] } = {
//     short: ["Task Force", "Organizing Committee", "Recruitment Task Force"],
//     mid: ["Team Leader", "National Support Team", "Recruitment Task Force"],
//     long: ["EB", "ECB"],
//     mc: ["NST"], // Role option for MC
//   };

//   const portfolios = [
//     "MKT & PR",
//     "iCX",
//     "oGV",
//     "oGT",
//     "EwA",
//     "BD",
//     "MXP",
//     "FnL",
//   ];

//   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setMetrics({ ...metrics, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e: React.FormEvent) => {
//     e.preventDefault();
//     const selectedRole = role === "custom" ? customRole : role;
  
//     // Add a check for portfolio requirement
//     if (term === "mid" && role === "Team Leader" && !portfolio) {
//       alert("Portfolio is required for Mid Term Team Leaders.");
//       return;
//     }
  
//     const formData = {
//       lc,
//       term: lc === "MC" ? "mid" : term, // Ensure term is set correctly
//       role: selectedRole,
//       portfolio: term === "mid" && role === "Team Leader"  ? portfolio : undefined,
//       metrics,
//     };
  
//     try {
//       await axios.post("https://api.nexusmxp.com/api/mxfunnel/submit", formData);
//       alert("Form submitted successfully!");
//       // Reset form
//       setLc("");
//       setTerm("");
//       setRole("");
//       setCustomRole("");
//       setPortfolio("");
//       setMetrics({
//         Leads: "",
//         Applicants: "",
//         Accepted: "",
//         Approved: "",
//         Realised: "",
//         Finished: "",
//         Completes: "",
//         Advanced: "",
//         Alumni: "",
//       });
//     } catch (error) {
//       console.error(error);
//       alert("Error submitting form");
//     }
//   };
  
//   // Automatically set the term to "mid" and role to "NST" if "MC" is selected
//   useEffect(() => {
//     if (lc === "MC") {
//       setTerm("mid");
//       setRole("NST");
//     } else {
//       setTerm("");
//       setRole("");
//     }
//   }, [lc]);

//   return (
//     <form onSubmit={handleSubmit} className="mx-auto p-4 max-w-lg">
//       <div className="mb-4">
//         <label className="block text-gray-700 font-bold mb-2">LC:</label>
//         <select
//           value={lc}
//           onChange={(e) => setLc(e.target.value)}
//           className="w-full p-2 border border-gray-300 rounded"
//           required
//         >
//           <option value="" disabled hidden>
//             Select LC
//           </option>
//           {lcOptions.map((lcOption, index) => (
//             <option key={index} value={lcOption}>
//               {lcOption}
//             </option>
//           ))}
//         </select>
//       </div>

//       {/* Only allow term selection when LC is not MC */}
//       {lc !== "MC" && (
//         <div className="mb-4">
//           <label className="block text-gray-700 font-bold mb-2">Term:</label>
//           <select
//             value={term}
//             onChange={(e) => setTerm(e.target.value)}
//             className="w-full p-2 border border-gray-300 rounded"
//             required
//           >
//             <option value="" disabled hidden>
//               Select Term
//             </option>
//             <option value="short">Short Term</option>
//             <option value="mid">Mid Term</option>
//             <option value="long">Long Term</option>
//           </select>
//         </div>
//       )}

//       {/* Portfolio selection for mid term and MC */}
//       {(term === "mid" && role === "Team Leader") || lc === "MC" ? (
//         <div className="mb-4">
//           <label className="block text-gray-700 font-bold mb-2">Portfolio:</label>
//           <select
//             value={portfolio}
//             onChange={(e) => setPortfolio(e.target.value)}
//             className="w-full p-2 border border-gray-300 rounded"
//             required
//           >
//             <option value="" disabled hidden>
//               Select Portfolio
//             </option>
//             {portfolios.map((portfolioOption, index) => (
//               <option key={index} value={portfolioOption}>
//                 {portfolioOption}
//               </option>
//             ))}
//           </select>
//         </div>
//       ) : null}

//       {/* Role selection for non-MC LCs */}
//       {lc !== "MC" && (
//         <div className="mb-4">
//           <label className="block text-gray-700 font-bold mb-2">Role:</label>
//           <select
//             value={role}
//             onChange={(e) => setRole(e.target.value)}
//             className="w-full p-2 border border-gray-300 rounded"
//             required
//           >
//             <option value="" disabled hidden>
//               Select Role
//             </option>
//             {roles[term]?.map((roleOption, index) => (
//               <option key={index} value={roleOption}>
//                 {roleOption}
//               </option>
//             ))}
//             <option value="custom">Add Custom Role</option>
//           </select>
//         </div>
//       )}

//       {/* Custom role input */}
//       {role === "custom" && (
//         <div className="mb-4">
//           <label className="block text-gray-700 font-bold mb-2">
//             Custom Role:
//           </label>
//           <input
//             type="text"
//             value={customRole}
//             onChange={(e) => setCustomRole(e.target.value)}
//             className="w-full p-2 border border-gray-300 rounded"
//             placeholder="Enter custom role"
//             required
//           />
//         </div>
//       )}

//       <h3 className="text-xl font-bold mb-4">Metrics:</h3>

//       {Object.keys(metrics).map((metricKey) => (
//         <div className="mb-4" key={metricKey}>
//           <label className="block text-gray-700 font-bold mb-2">
//             {metricKey
//               .replace(/([A-Z])/g, " $1")
//               .replace(/^./, (str) => str.toUpperCase())}
//             :
//           </label>
//           <input
//             type="number"
//             name={metricKey}
//             value={metrics[metricKey as keyof Metrics]}
//             onChange={handleInputChange}
//             className="w-full p-2 border border-gray-300 rounded"
//             placeholder="Enter number"
//             required
//           />
//         </div>
//       ))}

//       <button
//         type="submit"
//         className="w-full py-3 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 transition duration-200"
//       >
//         Submit
//       </button>
//     </form>
//   );
// };

// export default MXFunnelForm;

import React, { useState, useEffect } from "react";
import axios from "axios";

interface Metrics {
  Leads: string;
  Applicants: string;
  Accepted: string;
  Approved: string;
  Realised: string;
  Finished: string;
  Completes: string;
  Advanced: string;
  Alumni: string;
}

const MXFunnelForm: React.FC = () => {
  const [lc, setLc] = useState<string>("");
  const [term, setTerm] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [customRole, setCustomRole] = useState<string>("");
  const [portfolio, setPortfolio] = useState<string>("");
  const [metrics, setMetrics] = useState<Metrics>({
    Leads: "",
    Applicants: "",
    Accepted: "",
    Approved: "",
    Realised: "",
    Finished: "",
    Completes: "",
    Advanced: "",
    Alumni: "",
  });

  const lcOptions = [
    "Multan",
    "LUMS",
    "IBA",
    "Islamabad",
    "NUST",
    "South Karachi",
    "Karachi",
    "Hyderabad",
    "Lahore",
    "Faisalabad",
    "Giki",
    "MC", // Added MC option
  ];

  const roles: { [key: string]: string[] } = {
    short: ["Task Force", "Organizing Committee", "Recruitment Task Force"],
    mid: ["Team Leader", "National Support Team", "Recruitment Task Force"],
    long: ["EB", "ECB"],
    mc: ["NST"], // Role option for MC
  };

  const portfolios = [
    "MKT & PR",
    "iCX",
    "oGV",
    "oGT",
    "EwA",
    "BD",
    "MXP",
    "FnL",
  ];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMetrics({ ...metrics, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const selectedRole = role === "custom" ? customRole : role;

    // Check for portfolio requirement
    if ((term === "mid" && role === "Team Leader") || lc === "MC") {
      if (!portfolio) {
        alert("Portfolio is required.");
        return;
      }
    }

    const formData = {
      lc,
      term: lc === "MC" ? "mid" : term, // Ensure term is set correctly for MC
      role: selectedRole,
      portfolio: (term === "mid" && role === "Team Leader") || lc === "MC" ? portfolio : undefined,
      metrics,
    };

    try {
      await axios.post("https://api.nexusmxp.com/api/mxfunnel/submit", formData);
      alert("Form submitted successfully!");
      // Reset form
      setLc("");
      setTerm("");
      setRole("");
      setCustomRole("");
      setPortfolio("");
      setMetrics({
        Leads: "",
        Applicants: "",
        Accepted: "",
        Approved: "",
        Realised: "",
        Finished: "",
        Completes: "",
        Advanced: "",
        Alumni: "",
      });
    } catch (error) {
      console.error(error);
      alert("Error submitting form");
    }
  };

  // Automatically set the term to "mid" and role to "NST" if "MC" is selected
  useEffect(() => {
    if (lc === "MC") {
      setTerm("mid");
      setRole("NST");
    } else {
      setTerm("");
      setRole("");
    }
  }, [lc]);

  return (
    <form onSubmit={handleSubmit} className="mx-auto p-4 max-w-lg">
      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">LC:</label>
        <select
          value={lc}
          onChange={(e) => setLc(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded"
          required
        >
          <option value="" disabled hidden>
            Select LC
          </option>
          {lcOptions.map((lcOption, index) => (
            <option key={index} value={lcOption}>
              {lcOption}
            </option>
          ))}
        </select>
      </div>

      {/* Only allow term selection when LC is not MC */}
      {lc !== "MC" && (
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">Term:</label>
          <select
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          >
            <option value="" disabled hidden>
              Select Term
            </option>
            <option value="short">Short Term</option>
            <option value="mid">Mid Term</option>
            <option value="long">Long Term</option>
          </select>
        </div>
      )}

      {/* Portfolio selection for mid term and MC */}
      {(term === "mid" && role === "Team Leader") || lc === "MC" ? (
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">Portfolio:</label>
          <select
            value={portfolio}
            onChange={(e) => setPortfolio(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          >
            <option value="" disabled hidden>
              Select Portfolio
            </option>
            {portfolios.map((portfolioOption, index) => (
              <option key={index} value={portfolioOption}>
                {portfolioOption}
              </option>
            ))}
          </select>
        </div>
      ) : null}

      {/* Role selection for non-MC LCs */}
      {lc !== "MC" && (
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">Role:</label>
          <select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          >
            <option value="" disabled hidden>
              Select Role
            </option>
            {roles[term]?.map((roleOption, index) => (
              <option key={index} value={roleOption}>
                {roleOption}
              </option>
            ))}
            <option value="custom">Add Custom Role</option>
          </select>
        </div>
      )}

      {/* Custom role input */}
      {role === "custom" && (
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">
            Custom Role:
          </label>
          <input
            type="text"
            value={customRole}
            onChange={(e) => setCustomRole(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Enter custom role"
            required
          />
        </div>
      )}

      <h3 className="text-xl font-bold mb-4">Metrics:</h3>

      {Object.keys(metrics).map((metricKey) => (
        <div className="mb-4" key={metricKey}>
          <label className="block text-gray-700 font-bold mb-2">
            {metricKey
              .replace(/([A-Z])/g, " $1")
              .replace(/^./, (str) => str.toUpperCase())}
            :
          </label>
          <input
            type="number"
            name={metricKey}
            value={metrics[metricKey as keyof Metrics]}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Enter number"
            required
          />
        </div>
      ))}

      <button
        type="submit"
        className="w-full py-3 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 transition duration-200"
      >
        Submit
      </button>
    </form>
  );
};

export default MXFunnelForm;
