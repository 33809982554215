import React, { useState, useEffect } from 'react';
import { useUser } from '../contexts/UserContext';
import axios from 'axios';
import { getAuth, EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';

const ProfilePage: React.FC = () => {
  const { user, loading, role } = useUser();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [newName, setNewName] = useState('');
  const [message, setMessage] = useState('');
  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [profilePictureURL, setProfilePictureURL] = useState<string | null>(null);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordMessage, setPasswordMessage] = useState('');

  useEffect(() => {
    if (user) {
      setEmail(user.email || '');
      setName(user.displayName || '');
      setNewName(user.displayName || '');
      fetchProfilePicture();
    }
  }, [user]);

  const fetchProfilePicture = async () => {
    try {
      if (user) {
        const response = await axios.get(`https://api.nexusmxp.com/api/users/profile/${user.uid}/picture`);
        setProfilePictureURL(response.data.profilePictureURL);
      }
    } catch (error) {
      console.error('Error fetching profile picture:', error);
    }
  };

  const handleUpdateProfile = async () => {
    try {
      if (user) {
        const formData = new FormData();
        formData.append('displayName', newName || '');
        formData.append('role', role || 'user');
        formData.append('email', email || '');
        if (profilePicture) {
          formData.append('profilePicture', profilePicture);
        }

        const response = await axios.put(`https://api.nexusmxp.com/api/users/profile/${user.uid}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        setProfilePictureURL(response.data.user.photoURL);
        setMessage(response.data.message);
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      setMessage('Failed to update profile.');
    }
  };

  const handleProfilePictureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setProfilePicture(e.target.files[0]);
    }
  };

  const handleChangePassword = async () => {
    try {
      const currentUser = getAuth().currentUser;
      if (currentUser && currentPassword && newPassword) {
        const credential = EmailAuthProvider.credential(currentUser.email!, currentPassword);
        await reauthenticateWithCredential(currentUser, credential);
        await updatePassword(currentUser, newPassword);
        setPasswordMessage('Password updated successfully.');
      } else {
        setPasswordMessage('Please provide your current and new passwords.');
      }
    } catch (error) {
      console.error('Error changing password:', error);
      setPasswordMessage('Failed to change password. Please ensure your current password is correct.');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>You need to sign in to view your profile.</div>;
  }
  console.log("User:", role);
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
        <h1 className="text-2xl font-semibold mb-6">Profile Page</h1>
        <div className="flex justify-center mb-4">
          {profilePictureURL ? (
            <img
              src={profilePictureURL}
              alt="Profile"
              className="w-32 h-32 rounded-full object-cover"
            />
          ) : (
            <div className="w-32 h-32 rounded-full bg-gray-200 flex items-center justify-center text-gray-500">
              No Image
            </div>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            value={email}
            readOnly
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Name</label>
          <input
            type="text"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Role</label>
          <input
            type="text"
            value={role || 'user'}
            readOnly
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Profile Picture</label>
          <input
            type="file"
            onChange={handleProfilePictureChange}
            className="mt-1 block w-full text-sm text-gray-900 border-gray-300 rounded-md cursor-pointer focus:outline-none focus:border-transparent focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          />
        </div>
        <button
          onClick={handleUpdateProfile}
          className="w-full bg-indigo-600 text-white py-2 px-4 rounded hover:bg-indigo-500 transition duration-200"
        >
          Update Profile
        </button>
        {message && <p className="mt-4 text-center text-green-500">{message}</p>}
        
        <div className="mt-6">
          <h2 className="text-xl font-semibold mb-4">Change Password</h2>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Current Password</label>
            <input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">New Password</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <button
            onClick={handleChangePassword}
            className="w-full bg-red-600 text-white py-2 px-4 rounded hover:bg-red-500 transition duration-200"
          >
            Change Password
          </button>
          {passwordMessage && <p className="mt-4 text-center text-green-500">{passwordMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
