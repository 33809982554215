// src/components/GCPDetails.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Button } from './ui/moving-border';

interface GCP {
  _id: string;
  title: string;
  name: string;
  email: string;
  portfolio: string;
  category: string;
  localCommittee: string;
  gcpDetails: string;
  gcpLink: string;
  status: string;
  createdAt: string;
}

const GCPDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [gcp, setGcp] = useState<GCP | null>(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchGCP = async () => {
      try {
        const res = await axios.get(`https://api.nexusmxp.com/api/gcp/${id}`);
        setGcp(res.data);
      } catch (error) {
        console.error('Error fetching GCP:', error);
        setError('Failed to fetch GCP details');
      }
    };

    fetchGCP();
  }, [id]);

  if (error) return <p className="text-red-500">{error}</p>;
  if (!gcp) return <p>Loading...</p>;

  return (
    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-4xl p-6 bg-white rounded-lg shadow-lg flex flex-col gap-6">
      <h1 className="text-4xl font-bold text-center text-[#CBACF9]">{gcp.title}</h1>
      <div className="space-y-2">
        <p className="text-lg font-medium text-gray-900">Name: <span className="font-normal">{gcp.name}</span></p>
        <p className="text-lg font-medium text-gray-900">Email: <span className="font-normal">{gcp.email}</span></p>
        <p className="text-lg font-medium text-gray-900">Portfolio: <span className="font-normal">{gcp.portfolio}</span></p>
        <p className="text-lg font-medium text-gray-900">Category: <span className="font-normal">{gcp.category}</span></p>
        <p className="text-lg font-medium text-gray-900">Local Committee: <span className="font-normal">{gcp.localCommittee}</span></p>
        <p className="text-lg font-medium text-gray-900">Status: <span className={`font-normal ${gcp.status === 'approved' ? 'text-green-600' : gcp.status === 'rejected' ? 'text-red-600' : 'text-yellow-600'}`}>{gcp.status}</span></p>
        <p className="text-lg font-medium text-gray-900">Created At: <span className="font-normal">{new Date(gcp.createdAt).toLocaleString()}</span></p>
      </div>
      <p className="mt-4 text-gray-700">{gcp.gcpDetails}</p>
      <a href={gcp.gcpLink} target="_blank" rel="noopener noreferrer" className="mt-4 text-blue-500 hover:underline">
        <Button borderRadius="1.75rem"
            className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800">View GCP</Button>
      </a>
    </div>
  );
};

export default GCPDetails;
