import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const EditAPICycle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [documentUrl, setDocumentUrl] = useState('');
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    fetchAPICycleDetails();
  }, []);

  const fetchAPICycleDetails = async () => {
    try {
      const response = await axios.get(`https://api.nexusmxp.com/api/api-cycles/${id}`);
      const { title, description, documentUrl, imageUrl } = response.data;
      setTitle(title);
      setDescription(description);
      setDocumentUrl(documentUrl);
      setImageUrl(imageUrl); // Load the existing image URL
    } catch (error) {
      console.error('Error fetching API cycle details:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const formData = {
      title,
      description,
      documentUrl,
      imageUrl 
    };

    try {
      await axios.put(`https://api.nexusmxp.com/api/api-cycles/${id}`, formData);
      alert('API Cycle updated successfully');
      navigate('/apip-cycle');
    } catch (error) {
      console.error('Error updating APIP Cycle document:', error);
      alert('Failed to update APIP Cycle document');
    }
  };

  return (
    <div className="p-6 bg-gray-50 rounded-lg shadow-md max-w-4xl mx-auto">
      <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">Edit API Cycle Document</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="space-y-2">
          <label className="block text-gray-700 font-medium">Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div className="space-y-2">
          <label className="block text-gray-700 font-medium">Description</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          ></textarea>
        </div>
        <div className="space-y-2">
          <label className="block text-gray-700 font-medium">Document URL</label>
          <input
            type="url"
            value={documentUrl}
            onChange={(e) => setDocumentUrl(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div className="space-y-2">
          <label className="block text-gray-700 font-medium">Image URL</label>
          <input
            type="url"
            value={imageUrl}
            onChange={(e) => setImageUrl(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <button type="submit" className="w-full py-3 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 transition duration-200">
          Update
        </button>
      </form>
    </div>
  );
};

export default EditAPICycle;
