import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase/firebase';

const Logout: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const signOut = async () => {
      await auth.signOut();
      navigate('/signin');
    };

    signOut();
  }, [navigate]);

  return <div>Logging out...</div>;
};

export default Logout;
