// // // // import React, { useEffect, useState } from 'react';
// // // // import { Navigate } from 'react-router-dom';
// // // // import { auth } from '../firebase/firebase';
// // // // import { onAuthStateChanged, User } from 'firebase/auth';
// // // // import { useUser } from '../contexts/UserContext';
// // // // import AccessDeniedPopup from '../components/ui/AccessDeniedPopup';

// // // // interface ProtectedRouteProps {
// // // //   component: React.ComponentType<any>;
// // // //   requiredRole?: string;
// // // // }

// // // // const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, requiredRole }) => {
// // // //   const [loading, setLoading] = useState(true);
// // // //   const [authenticated, setAuthenticated] = useState(false);
// // // //   const [hasPermission, setHasPermission] = useState(false);
// // // //   const [showPopup, setShowPopup] = useState(false);
// // // //   const { role } = useUser();

// // // //   useEffect(() => {
// // // //     const unsubscribe = onAuthStateChanged(auth, (user: User | null) => {
// // // //       if (user && user.email && user.email.endsWith('@aiesec.net')) {
// // // //         setAuthenticated(true);
// // // //         if (!requiredRole || role === requiredRole || role === 'admin') {
// // // //           setHasPermission(true);
// // // //         } else {
// // // //           setHasPermission(false);
// // // //           setShowPopup(true);
// // // //           setTimeout(() => setShowPopup(false), 3000); // Hide popup after 3 seconds
// // // //         }
// // // //       } else {
// // // //         setAuthenticated(false);
// // // //         setHasPermission(false);
// // // //       }
// // // //       setLoading(false);
// // // //     });

// // // //     return () => unsubscribe();
// // // //   }, [role, requiredRole]);

// // // //   if (loading) {
// // // //     return <div>Loading...</div>;
// // // //   }

// // // //   return (
// // // //     <>
// // // //       {authenticated && hasPermission ? (
// // // //         <Component />
// // // //       ) : (
// // // //         showPopup && <AccessDeniedPopup />
// // // //       )}
// // // //     </>
// // // //   );
// // // // };

// // // // export default ProtectedRoute;

// // // import React, { useEffect, useState } from 'react';
// // // import { Navigate } from 'react-router-dom';
// // // import { auth } from '../firebase/firebase';
// // // import { onAuthStateChanged, User } from 'firebase/auth';
// // // import { useUser } from '../contexts/UserContext';
// // // import AccessDeniedPopup from '../components/ui/AccessDeniedPopup';

// // // interface ProtectedRouteProps {
// // //   component: React.ComponentType<any>;
// // //   requiredRole?: string;
// // // }

// // // const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, requiredRole }) => {
// // //   const [loading, setLoading] = useState(true);
// // //   const [authenticated, setAuthenticated] = useState(false);
// // //   const [hasPermission, setHasPermission] = useState(false);
// // //   const [showPopup, setShowPopup] = useState(false);
// // //   const { role } = useUser();

// // //   useEffect(() => {
// // //     const unsubscribe = onAuthStateChanged(auth, (user: User | null) => {
// // //       if (user && user.email && user.email.endsWith('@aiesec.net')) {
// // //         setAuthenticated(true);
// // //         if (!requiredRole || role === requiredRole || role === 'admin' || (role && roleHierarchy[role] >= roleHierarchy[requiredRole])) {
// // //           setHasPermission(true);
// // //         } else {
// // //           setHasPermission(false);
// // //           setShowPopup(true);
// // //           setTimeout(() => setShowPopup(false), 3000); // Hide popup after 3 seconds
// // //         }
// // //       } else {
// // //         setAuthenticated(false);
// // //         setHasPermission(false);
// // //       }
// // //       setLoading(false);
// // //     });

// // //     return () => unsubscribe();
// // //   }, [role, requiredRole]);

// // //   const roleHierarchy: { [key: string]: number } = {
// // //     'user': 1,
// // //     'tm': 2,
// // //     'tl': 3,
// // //     'lcvp': 4,
// // //     'mcvp': 5,
// // //     'admin': 6
// // //   };

// // //   if (loading) {
// // //     return <div>Loading...</div>;
// // //   }

// // //   return (
// // //     <>
// // //       {authenticated && hasPermission ? (
// // //         <Component />
// // //       ) : (
// // //         showPopup && <AccessDeniedPopup />
// // //       )}
// // //     </>
// // //   );
// // // };

// // // export default ProtectedRoute;

// // import React, { useEffect, useState } from 'react';
// // import { Navigate } from 'react-router-dom';
// // import { auth } from '../firebase/firebase';
// // import { onAuthStateChanged, User } from 'firebase/auth';
// // import { useUser } from '../contexts/UserContext';
// // import AccessDeniedPopup from '../components/ui/AccessDeniedPopup';

// // interface ProtectedRouteProps {
// //   component: React.ComponentType<any>;
// //   requiredRoles?: string[];
// // }

// // const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, requiredRoles }) => {
// //   const [loading, setLoading] = useState(true);
// //   const [authenticated, setAuthenticated] = useState(false);
// //   const [hasPermission, setHasPermission] = useState(false);
// //   const [showPopup, setShowPopup] = useState(false);
// //   const { role } = useUser();

// //   useEffect(() => {
// //     const unsubscribe = onAuthStateChanged(auth, (user: User | null) => {
// //       if (user && user.email && user.email.endsWith('@aiesec.net')) {
// //         setAuthenticated(true);
// //         if (!requiredRoles || requiredRoles.includes(role) || role === 'admin') {
// //           setHasPermission(true);
// //         } else {
// //           setHasPermission(false);
// //           setShowPopup(true);
// //           setTimeout(() => setShowPopup(false), 3000); // Hide popup after 3 seconds
// //         }
// //       } else {
// //         setAuthenticated(false);
// //         setHasPermission(false);
// //       }
// //       setLoading(false);
// //     });

// //     return () => unsubscribe();
// //   }, [role, requiredRoles]);

// //   if (loading) {
// //     return <div>Loading...</div>;
// //   }

// //   return (
// //     <>
// //       {authenticated && hasPermission ? (
// //         <Component />
// //       ) : (
// //         showPopup && <AccessDeniedPopup />
// //       )}
// //     </>
// //   );
// // };

// // export default ProtectedRoute;

// import React, { useEffect, useState } from 'react';
// import { Navigate } from 'react-router-dom';
// import { auth } from '../firebase/firebase';
// import { onAuthStateChanged, User } from 'firebase/auth';
// import { useUser } from '../contexts/UserContext';
// import AccessDeniedPopup from '../components/ui/AccessDeniedPopup';

// interface ProtectedRouteProps {
//   component: React.ComponentType<any>;
//   requiredRoles?: string[];
// }

// const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, requiredRoles }) => {
//   const [loading, setLoading] = useState(true);
//   const [authenticated, setAuthenticated] = useState(false);
//   const [hasPermission, setHasPermission] = useState(false);
//   const [showPopup, setShowPopup] = useState(false);
//   const { role } = useUser();

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, (user: User | null) => {
//       if (user && user.email && user.email.endsWith('@aiesec.net')) {
//         setAuthenticated(true);
//         if (!requiredRoles || requiredRoles.includes(role) || role === 'admin') {
//           setHasPermission(true);
//         } else {
//           setHasPermission(false);
//           setShowPopup(true);
//           setTimeout(() => setShowPopup(false), 3000); // Hide popup after 3 seconds
//         }
//       } else {
//         setAuthenticated(false);
//         setHasPermission(false);
//       }
//       setLoading(false);
//     });

//     return () => unsubscribe();
//   }, [role, requiredRoles]);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <>
//       {authenticated && hasPermission ? (
//         <Component />
//       ) : (
//         showPopup && <AccessDeniedPopup />
//       )}
//     </>
//   );
// };

// export default ProtectedRoute;
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { auth } from '../firebase/firebase';
import { onAuthStateChanged, User } from 'firebase/auth';
import { useUser } from '../contexts/UserContext';
import AccessDeniedPopup from '../components/ui/AccessDeniedPopup';

interface ProtectedRouteProps {
  component: React.ComponentType<any>;
  requiredRoles?: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, requiredRoles }) => {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const { role } = useUser();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user: User | null) => {
      if (user && user.email && user.email.endsWith('@aiesec.net')) {
        setAuthenticated(true);
        if (!requiredRoles || requiredRoles.includes(role as string) || role === 'admin') {
          setHasPermission(true);
        } else {
          setHasPermission(false);
          setShowPopup(true);
          setTimeout(() => setShowPopup(false), 3000); // Hide popup after 3 seconds
        }
      } else {
        setAuthenticated(false);
        setHasPermission(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [role, requiredRoles]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {authenticated && hasPermission ? (
        <Component />
      ) : (
        showPopup && <AccessDeniedPopup />
      )}
    </>
  );
};

export default ProtectedRoute;
