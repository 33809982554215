import React from 'react';
import { useUser } from '../contexts/UserContext';
import MultiStepChecklistForm from "./ui/MultiStepForm";

const MXStandards: React.FC = () => {
  const { userId, loading } = useUser();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 px-4">
      <div className="w-full max-w-4xl p-6">
        {userId ? (
          <MultiStepChecklistForm userId={userId} />
        ) : (
          <div>No user signed in</div>
        )}
      </div>
    </div>
  );
};

export default MXStandards;
