// src/components/Home.tsx
import React from "react";
import { useNavigate } from "react-router-dom";
import { Spotlight } from "./ui/Spotlight";
import { SparklesCore } from "./ui/sparkles";
import { Button } from "./ui/moving-border";

const Home: React.FC = () => {
  const navigate = useNavigate();
  const isAuthenticated = !!localStorage.getItem("token");

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/signin");
  };

  return (
    <div className="relative min-h-screen bg-gray-100">
      <div className="absolute inset-0 overflow-hidden">
        <div className="h-full w-full bg-black flex flex-col items-center justify-center overflow-hidden rounded-md">
          {/* <h1 className="md:text-7xl text-3xl lg:text-9xl font-bold text-center text-purple relative z-20 ">
            Nexus
          </h1> */}
          <img src="nexus.png" alt="" width={500} height={400} className="animate-fadeIn"/>
          <div className="w-[40rem]ƒ h-40 relative">
            {/* Gradients */}
            <div className="absolute inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-[2px] w-3/4 blur-sm" />
            <div className="aƒbsolute inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-px w-3/4" />
            <div className="absolute inset-x-60 top-0 bg-gradient-to-r from-transparent via-sky-500 to-transparent h-[5px] w-1/4 blur-sm" />
            <div className="absolute inset-x-60 top-0 bg-gradient-to-r from-transparent via-sky-500 to-transparent h-px w-1/4" />

            {/* Core component */}
            <SparklesCore
              background="transparent"
              minSize={0.4}
              maxSize={1}
              particleDensity={1200}
              className="w-full h-full"
              particleColor="#FFFFFF"
            />

            {/* Radial Gradient to prevent sharp edges */}
            <div className="absolute inset-0 w-full h-full bg-black [mask-image:radial-gradient(350px_200px_at_top,transparent_20%,white)]"></div>
          </div>
          <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0 items-center justify-center">
            {!isAuthenticated ? (
              <Button
                onClick={() => navigate("/signin")}
                borderRadius="1.75rem"
                className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800 border-black"
              >
                Login
              </Button>
            ) : (
              <Button
                onClick={handleLogout}
                borderRadius="1.75rem"
                className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800 border-black"
              >
                Logout
              </Button>
            )}
            <Button
              onClick={() => navigate("/signup")}
              borderRadius="1.75rem"
              className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800 border-black"
            >
              Signup
            </Button>
            <Button
              onClick={() => navigate("/recruitment-form")}
              borderRadius="1.75rem"
              className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800 border-black"
            >
              Recruitment Form
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
