import { cn } from "../../lib/utils";

export const LampContainer = ({
    children,
    className,
  }: {
    children: React.ReactNode;
    className?: string;
  }) => {
    return (
      <div
        className={cn(
          "relative flex items-center justify-center overflow-hidden bg-transparent w-full",
          className
        )}
      >
        <div className="relative flex w-full flex-col items-center justify-center">
          {children}
        </div>
      </div>
    );
  };