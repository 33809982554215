import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const DocumentUpload = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [documentUrl, setDocumentUrl] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [documents, setDocuments] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    fetchDocuments(currentPage);
  }, [currentPage]);

  const fetchDocuments = async (page: number) => {
    try {
      const response = await axios.get(
        `https://api.nexusmxp.com/api/documents?page=${page}&limit=6`
      );
      if (response.data && Array.isArray(response.data.data)) {
        setDocuments(response.data.data);
        setTotalPages(response.data.totalPages);
      } else {
        console.error("Unexpected response format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const handleDelete = async (id: string) => {
    console.log("Deleting document with id:", id);
    try {
      await axios.delete(`https://api.nexusmxp.com/api/documents/${id}`);
      fetchDocuments(currentPage); // Refresh the current page after deletion
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const formData = {
      title,
      description,
      documentUrl,
      imageUrl,
    };

    try {
      await axios.post("https://api.nexusmxp.com/api/documents", formData);
      setTitle("");
      setDescription("");
      setDocumentUrl("");
      setImageUrl("");
      fetchDocuments(currentPage); // Refresh the document list
      alert("Document uploaded successfully");
    } catch (error) {
      console.error("Error uploading document:", error);
    }
  };

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="p-6 bg-gray-50 rounded-lg shadow-md max-w-4xl mx-auto">
      <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">
        Upload Document
      </h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Form fields remain the same */}
        <div className="space-y-2">
          <label className="block text-gray-700 font-medium">Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div className="space-y-2">
          <label className="block text-gray-700 font-medium">Description</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          ></textarea>
        </div>
        <div className="space-y-2">
          <label className="block text-gray-700 font-medium">
            Document URL
          </label>
          <input
            type="url"
            value={documentUrl}
            onChange={(e) => setDocumentUrl(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div className="space-y-2">
          <label className="block text-gray-700 font-medium">Image URL</label>
          <input
            type="url"
            value={imageUrl}
            onChange={(e) => setImageUrl(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <button
          type="submit"
          className="w-full py-3 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 transition"
        >
          Upload
        </button>
      </form>

      <h2 className="text-3xl font-bold mt-10 mb-6 text-center text-gray-800">
        Uploaded Documents
      </h2>
      {documents.length === 0 ? (
        <p className="text-center text-gray-600">No documents uploaded yet.</p>
      ) : (
        <ul className="space-y-4">
          {documents.map((document) => (
            <li
              key={document._id}
              className="p-4 bg-white border border-gray-200 rounded-lg shadow-md"
            >
              <h3 className="text-lg font-bold mb-2 text-gray-900">
                {document.title}
              </h3>
              <p className="text-gray-700 mb-2">{document.description}</p>
              <a
                href={document.documentUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 underline"
              >
                Document Link
              </a>
              <div className="flex items-center mt-4">
                {document.imageUrl && (
                  <img
                    src={document.imageUrl}
                    alt={document.title}
                    className="w-20 h-20 mr-4 rounded-lg border border-gray-300 object-cover shadow-md"
                  />
                )}
                <button
                  onClick={() => handleDelete(document._id)}
                  className="ml-auto px-4 py-2 bg-red-600 text-white rounded-lg shadow-md hover:bg-red-700 transition duration-200"
                >
                  Delete
                </button>
                <button
                  onClick={() => navigate(`/edit-document/${document._id}`)}
                  className="ml-4 px-4 py-2 bg-yellow-500 text-white rounded-lg shadow-md hover:bg-yellow-600 transition duration-200"
                >
                  Edit
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}

      {/* Pagination Controls */}
      <div className="flex justify-center space-x-4 mt-8">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          className="px-4 py-2 bg-gray-200 rounded-lg hover:bg-gray-300"
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="px-4 py-2 bg-gray-100 rounded-lg">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          className="px-4 py-2 bg-gray-200 rounded-lg hover:bg-gray-300"
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default DocumentUpload;
