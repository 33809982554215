import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Button } from "./ui/moving-border";

const GCPForm: React.FC = () => {
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [portfolio, setPortfolio] = useState("");
  const [category, setCategory] = useState("");
  const [localCommittee, setLocalCommittee] = useState("");
  const [gcpDetails, setGcpDetails] = useState("");
  const [gcpLink, setGcpLink] = useState("");
  const [imageLink, setImageLink] = useState(""); // Store Google Drive link as a string
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const extractFileIdFromDriveLink = (driveLink: string) => {
    const fileIdMatch = driveLink.match(/[-\w]{25,}/);
    return fileIdMatch ? fileIdMatch[0] : null;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const fileId = extractFileIdFromDriveLink(imageLink);
    if (!fileId) {
      setMessage("Invalid Google Drive link");
      return;
    }

    const formData = {
      title,
      name,
      email,
      portfolio,
      category,
      localCommittee,
      gcpDetails,
      gcpLink,
      imageLink: `https://drive.google.com/uc?export=view&id=${fileId}`, // Store the direct link
    };

    try {
      const res = await axios.post(
        "https://api.nexusmxp.com/api/gcp",
        formData
      );
      console.log(res.data);
      setMessage("GCP submission successful");
      navigate("/gcplist");
    } catch (error) {
      console.error(error);
      setMessage("GCP submission failed");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 sm:p-12 w-full max-w-lg">
        <h1 className="text-4xl font-extrabold text-gray-800 mb-6 text-center">
          GCP Form
        </h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Title
            </label>
            <input
              className="mt-1 block w-full rounded-md border-2 border-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-lg sm:py-2"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input
              className="mt-1 block w-full rounded-md border-2 border-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-lg sm:py-2"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              className="mt-1 block w-full rounded-md border-2 border-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-lg sm:py-2"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Portfolio
            </label>
            <input
              className="mt-1 block w-full rounded-md border-2 border-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-lg sm:py-2"
              type="text"
              value={portfolio}
              onChange={(e) => setPortfolio(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Category
            </label>
            <input
              className="mt-1 block w-full rounded-md border-2 border-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-lg sm:py-2"
              type="text"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Local Committee
            </label>
            <input
              className="mt-1 block w-full rounded-md border-2 border-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-lg sm:py-2"
              type="text"
              value={localCommittee}
              onChange={(e) => setLocalCommittee(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              GCP Details
            </label>
            <textarea
              className="mt-1 block w-full rounded-md border-2 border-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-lg sm:py-2"
              value={gcpDetails}
              onChange={(e) => setGcpDetails(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Link to GCP
            </label>
            <input
              className="mt-1 block w-full rounded-md border-2 border-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-lg sm:py-2"
              type="url"
              value={gcpLink}
              onChange={(e) => setGcpLink(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Google Drive Image Link
            </label>
            <input
              className="mt-1 block w-full rounded-md border-2 border-gray-400 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-lg sm:py-2"
              type="url"
              value={imageLink}
              onChange={(e) => setImageLink(e.target.value)}
              required
            />
          </div>
          <Button
            borderRadius="1.75rem"
            className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800"
            type="submit"
          >
            Submit
          </Button>
        </form>
        {message && (
          <p className="mt-5 font-semibold text-center text-gray-700">
            {message}
          </p>
        )}
      </div>
    </div>
  );
};

export default GCPForm;
