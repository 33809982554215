import MagicButton from "./ui/MagicButton";
import { Spotlight } from "./ui/Spotlight";
import { TextGenerateEffect } from "./ui/TextGenerateEffect";
import { FaLocationArrow } from "react-icons/fa";

const Hero = ({ loc }: { loc: string }) => {
  return (
      <div className=" flex-col justify-center items-center min-h-screen w-full rounded-md flex md:items-center md:justify-center bg-black/[1] antialiased bg-grid-white/[0.00] relative overflow-hidden">
      <Spotlight
        className="-top-40 left-20 md:left-60 md:-top-20"
        fill="white"
      />
       <div className="flex justify-center relative my-10 z-30">
        <div className="max-w-[89vw] md:max-w-2xl lg:max-w-[60vw] flex flex-col items-center justify-center">
          <img
            src="nexus.png"
            alt=""
            width={500}
            height={400}
            className="pb-5 animate-fadeIn"
          />
          <TextGenerateEffect
            className="text-center text-[40px] md:text-5xl lg:text-6xl text-purple-900 dark:text-purple-200"
            words="Your Gateway to Our World"
            color="white"
          />
          <p className="text-center md:tracking-wider mb-4 text-sm md:text-lg lg:text-2xl text-white">
            MX Commission
          </p>
          {/* <a href={`#${loc}`}>
            <MagicButton title="Panel" icon={<FaLocationArrow />} position="right" />
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
