// import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
// import { onAuthStateChanged } from 'firebase/auth';
// import { doc, getDoc } from 'firebase/firestore';
// import { auth, db } from '../firebase/firebase';

// type Role = 'user' | 'tm' | 'tl' | 'lcvp' | 'mcvp' | 'admin';

// interface UserContextType {
//   user: any;
//   role: Role | null;
//   loading: boolean;
//   setRole: (role: Role | null) => void;
// }

// interface UserProviderProps {
//   children: ReactNode;
// }

// export const UserContext = createContext<UserContextType | undefined>(undefined);

// export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
//   const [user, setUser] = useState<any>(null);
//   const [role, setRole] = useState<Role | null>(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
//       if (currentUser) {
//         const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
//         if (userDoc.exists()) {
//           const userData = userDoc.data();
//           setUser(currentUser);
//           setRole(userData.role as Role);
//         }
//       } else {
//         setUser(null);
//         setRole(null);
//       }
//       setLoading(false);
//     });

//     return () => unsubscribe();
//   }, []);

//   return (
//     <UserContext.Provider value={{ user, role, loading, setRole }}>
//       {children}
//     </UserContext.Provider>
//   );
// };

// export const useUser = () => {
//   const context = useContext(UserContext);
//   if (!context) {
//     throw new Error('useUser must be used within a UserProvider');
//   }
//   return context;
// };

import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase/firebase';

type Role = 'user' | 'tm' | 'tl' | 'lcvp' | 'mcvp' | 'admin';

interface UserContextType {
  user: any;
  userId: string | null;
  role: Role | null;
  loading: boolean;
  setRole: (role: Role | null) => void;
}

interface UserProviderProps {
  children: ReactNode;
}

export const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<any>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [role, setRole] = useState<Role | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUser(currentUser);
          setUserId(currentUser.uid);  // Set the userId from the authenticated user
          setRole(userData.role as Role);
        }
      } else {
        setUser(null);
        setUserId(null);
        setRole(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider value={{ user, userId, role, loading, setRole }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
