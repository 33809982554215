import React, { useEffect, useState } from "react";
import {
  HashRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Signup from "./components/Signup";
import Signin from "./components/Signin";
import Home from "./components/Home";
import GCPForm from "./components/GCPForm";
import GCPList from "./components/GCPList";
import GCPDetails from "./components/GCPDetails";
import { FloatingNav } from "./components/ui/floating-navbar";
import Logout from "./components/Logout";
import Footer from "./components/Footer";
import ProtectedRoute from "./routes/ProtectedRoute";
import MXStandards from "./components/MXStandards";
import { UserProvider, useUser } from "./contexts/UserContext";
import AdminPanel from "./components/AdminPanel";
import ProfilePage from "./components/ProfilePage";
import axios from "axios";
import SonaSurvey from "./components/SonaSurvey";
import ResetPassword from "./components/ResetPassword";
import ResourceHub from "./components/ResourceHub";
import DocumentUpload from "./components/DocumentUpload";
import APICycleHub from "./components/APIPCycle";
import APICycleUpload from "./components/ApipUpload";
import MXResults from "./components/MXResults";
import { MultiStepLoader } from "./components/ui/multi-step-loader";
import Main from "./components/Main";
import EditAPICycle from "./components/EditApipCycle";
import EditDocument from "./components/EditDocument";
import EditGCPForm from "./components/EditGCPForm";
import ODModel from "./components/ODModel";
import MXFunnelForm from "./components/MXFunnelForm";
import MXFunnel from "./components/MXFunnel";
import RecruitmentForm from "./components/RecruitmentForm";

interface RoutePermission {
  _id: string;
  route: string;
  accessRoles: string[];
}

const loadingStates = [
  { text: "Fetching permissions" },
  { text: "Processing data" },
  { text: "Loading components" },
  { text: "Almost there" },
];

const MainContent: React.FC = () => {
  const { user, role, loading } = useUser();
  const location = useLocation();
  const [routePermissions, setRoutePermissions] = useState<RoutePermission[]>(
    []
  );
  const [navItems, setNavItems] = useState<{ name: string; link: string }[]>(
    []
  );
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchRoutePermissions = async () => {
      try {
        const response = await axios.get(
          "https://api.nexusmxp.com/api/route-permissions"
        );
        setPermissionsLoaded(true);
        setRoutePermissions(response.data);
        console.log("Route permissions:", response.data);
      } catch (error) {
        console.error("Error fetching route permissions:", error);
        setError("Failed to fetch route permissions");
      }
    };

    fetchRoutePermissions();
  }, []);

  useEffect(() => {
    const commonNavItems = [
      { name: "Home", link: "#/" },
      { name: "Signin", link: "#/signin" },
      { name: "Signup", link: "#/signup" },
    ];

    const userNavItems = [
      { name: "Dashboard", link: "#/dashboard" },
      { name: "Profile", link: "#/profile" },
      { name: "Logout", link: "#/logout" },
    ];

    const adminNavItems = [
      { name: "Dashboard", link: "#/dashboard" },
      { name: "Profile", link: "#/profile" },
      { name: "Admin Panel", link: "#/admin-panel" },
      { name: "Resource Upload", link: "#/document-upload" },
      { name: "APIP Upload", link: "#/apip-uploads" },
      { name: "MX Results", link: "#/mx-results" },
      { name: "Logout", link: "#/logout" },
    ];
    const mcvpNavItems = [
      { name: "Dashboard", link: "#/dashboard" },
      { name: "Profile", link: "#/profile" },
      { name: "Resource Upload", link: "#/document-upload" },
      { name: "APIP Upload", link: "#/apip-uploads" },
      { name: "MX Results", link: "#/mx-results" },
      { name: "Logout", link: "#/logout" },
    ];

    if (user && role) {
      if (role === "admin") {
        setNavItems(adminNavItems);
      } else if (role === "mcvp") {
        setNavItems(mcvpNavItems);
      } else {
        setNavItems(userNavItems);
      }
    } else {
      setNavItems(commonNavItems);
    }
  }, [user, role]);

  if (loading || !permissionsLoaded) {
    return <MultiStepLoader loadingStates={loadingStates} loading={true} />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const hideNavRoutes = ["/signup", "/signin", "/"];
  const shouldShowNav = !hideNavRoutes.includes(location.pathname);

  return (
    <div className="flex flex-col min-h-screen overflow-clip mb-0">
      <header className="">
        {shouldShowNav && <FloatingNav navItems={navItems} />}
      </header>
      <main className="flex-grow">
        <Routes>
          <Route path="/signup" element={<Signup />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/recruitment-form" element={<RecruitmentForm/>} />
          <Route path="/" element={<Home />} />
          {routePermissions.map((permission) => (
            <Route
              key={permission._id}
              path={permission.route}
              element={
                <ProtectedRoute
                  component={getComponentForRoute(permission.route)}
                  requiredRoles={permission.accessRoles || []}
                />
              }
            />
          ))}
          <Route
            path="/logout"
            element={<ProtectedRoute component={Logout} />}
          />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

const getComponentForRoute = (route: string) => {
  switch (route) {
    case "/dashboard":
      return Main;
    case "/gcpform":
      return GCPForm;
    case "/gcplist":
      return GCPList;
    case "/gcps/:id":
      return GCPDetails;
    case "/mx-standards":
      return MXStandards;
    case "/admin-panel":
      return AdminPanel;
    case "/profile":
      return ProfilePage;
    case "/sona-survey":
      return SonaSurvey;
    case "/resource-hub":
      return ResourceHub;
    case "/document-upload":
      return DocumentUpload;
    case "/apip-cycle":
      return APICycleHub;
    case "/apip-uploads":
      return APICycleUpload;
    case "/mx-results":
      return MXResults;
    case "/edit-apip-cycle/:id":
      return EditAPICycle;
    case "/edit-document/:id":
      return EditDocument;
    case "/edit-gcp/:id":
      return EditGCPForm;
    case '/od-model':
      return ODModel;
    case '/mx-funnel':
      return MXFunnel;
    case '/mx-funnel-form':
      return MXFunnelForm;
    default:
      return Home;
  }
};

const App: React.FC = () => {
  return (
    <UserProvider>
      <Router>
        <MainContent />
      </Router>
    </UserProvider>
  );
};

export default App;
