import React from "react";
import { EvervaultCard, Icon } from "./ui/evervault-card";

const ODModel = () => {
  return (
    <div className="bg-white dark:bg-black w-full h-full flex flex-col items-center justify-center p-8">
      <h1 className="font-playfair text-3xl lg:text-5xl md:text-4xl sm:text-3xl text-black dark:text-white mb-8 p-10">
        Organizational Development Models
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        <div className="border border-black/[0.2] dark:border-white/[0.2] flex flex-col items-center max-w-sm mx-auto p-6 relative h-[30rem] rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out">
          <Icon className="absolute h-6 w-6 -top-3 -left-3 dark:text-white text-black" />
          <Icon className="absolute h-6 w-6 -bottom-3 -left-3 dark:text-white text-black" />
          <Icon className="absolute h-6 w-6 -top-3 -right-3 dark:text-white text-black" />
          <Icon className="absolute h-6 w-6 -bottom-3 -right-3 dark:text-white text-black" />

          <EvervaultCard text="International OD Model" />

          <h2 className="dark:text-white text-black mt-4 text-lg font-medium text-center">
            International OD Model of AIESEC International
          </h2>
          <a
            href="https://drive.google.com/drive/folders/12axl7TI5voXGAKPIAlAELYkgdX6kMQZX"
            target="_blank"
            rel="noopener noreferrer"
            className="block"
          >
            <p className="text-sm font-light dark:border-white/[0.2] border-black/[0.2] rounded-full mt-4 text-black dark:text-white px-4 py-1 transition-colors duration-200 ease-in-out cursor-pointer hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black">
              Click to view
            </p>
          </a>
        </div>

        <div className="border border-black/[0.2] dark:border-white/[0.2] flex flex-col items-center max-w-sm mx-auto p-6 relative h-[30rem] rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out">
          <Icon className="absolute h-6 w-6 -top-3 -left-3 dark:text-white text-black" />
          <Icon className="absolute h-6 w-6 -bottom-3 -left-3 dark:text-white text-black" />
          <Icon className="absolute h-6 w-6 -top-3 -right-3 dark:text-white text-black" />
          <Icon className="absolute h-6 w-6 -bottom-3 -right-3 dark:text-white text-black" />

          <EvervaultCard text="National OD Model" />

          <h2 className="dark:text-white text-black mt-4 text-lg font-medium text-center">
            National OD Model of AIESEC in Pakistan
          </h2>
          <a
  href="#"
  target="_blank"
  rel="noopener noreferrer"
  className="block"
>
  <p className="text-sm font-light dark:border-white/[0.2] border-black/[0.2] rounded-full mt-4 text-black dark:text-white px-4 py-1 transition-colors duration-200 ease-in-out cursor-pointer hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black">
    Coming Soon
  </p>
</a>

        </div>
      </div>
    </div>
  );
};

export default ODModel;
